import { AsyncPipe, DOCUMENT, NgClass, isPlatformBrowser } from '@angular/common';
import {
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  afterNextRender,
  computed,
  effect,
  inject,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BasicThemedComponentDirective, ScrollService, WebsiteThemes } from '@effy-tech/acquisition-core/shared';
import { EffyButtonStyle } from '@effy-tech/angular-common/button';
import { LoadImagesService } from '../../services/load-images.service.ts/load-images.service';
import { ConnectionComponent } from '../connection/connection.component';
import { CtaButtonComponent } from '../cta/cta-button/cta-button.component';
import { LogoComponent } from '../logo/logo.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { PhoneBlockComponent } from '../phone-block/phone-block.component';
import { HEADER_HEIGHT, IHeader, PhoneType } from './header';

@Component({
  selector: 'nscf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [LogoComponent, PhoneBlockComponent, NavbarComponent, ConnectionComponent, CtaButtonComponent, AsyncPipe, NgClass],
  providers: [LoadImagesService],
  host: {
    ngSkipHydration: 'true',
  },
})
export class HeaderComponent extends BasicThemedComponentDirective implements OnInit {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly document = inject(DOCUMENT);
  private readonly scrollService = inject(ScrollService);
  private readonly destroyRef = inject(DestroyRef);
  @ViewChild('menuMobile', { static: true }) menuMobile: ElementRef<HTMLInputElement>;
  @Input() data: IHeader;
  protected readonly scrollOverHeightHeader = signal(false);
  private readonly lastScrollPosition = signal(0);
  protected readonly scrollBack = signal(false);
  connected = signal(false);
  isRegulatory: boolean;
  isBrowser = isPlatformBrowser(this.platformId);
  readonly isMobileMenuOpen = signal(false);
  readonly bodyOverflow = computed<'auto' | 'hidden'>(() => (this.isMobileMenuOpen() ? 'hidden' : 'auto'));
  secondButtonStyle = EffyButtonStyle.QUATERNARY;

  readonly mobileMenuOpened = output<boolean>();

  constructor() {
    super();
    afterNextRender(() => {
      this.scrollService.scroll$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.onWindowScroll());
    });

    effect(() => (this.document.body.style.overflow = this.bodyOverflow()));
    effect(() => this.mobileMenuOpened.emit(this.isMobileMenuOpen()));
  }

  ngOnInit(): void {
    this.isRegulatory = this.data.phoneInsert?.phoneType === PhoneType.Reglementaire;
    this.secondButtonStyle = this.theme === WebsiteThemes.effy ? EffyButtonStyle.QUATERNARY : EffyButtonStyle.TERTIARY;

    if (this.data?.shouldDisplayPhoneInsert === false) {
      this.data.phoneInsert = null;
    }
  }

  onWindowScroll(): void {
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.scrollOverHeightHeader.set(verticalOffset > HEADER_HEIGHT);
    this.scrollBack.set(verticalOffset < this.lastScrollPosition());
    this.lastScrollPosition.set(verticalOffset);
  }

  onMenuMobileChange($event: Event) {
    const element = $event.target as HTMLInputElement;
    this.isMobileMenuOpen.set(element.checked);
  }

  onConnected(connected: boolean) {
    this.connected.set(connected);
  }
}
