import { VideoGenerator } from '../../classes/video-generator/video-generator';
import { DisplayValues, FilterValues, IMedias, IMediasBo, IMediasCardBo, TypeNameBlockMedia } from '../../components/medias/medias';
import {
  IMediasCardsArticle,
  IMediasCardsVideo,
  TypeNameMediaCardArticle,
  TypeNameMediaCardVideo,
} from '../../components/medias/medias-card';
import { IFieldMediaImage, IFieldMediaImageWithOnlyWebp } from '../../interfaces/field-media-image';
import { MediaFilePresenter } from '../commons/media-file.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { RTEPresenter } from '../commons/rte.presenter';
import { IBlockPresenter } from '../presenter';

export class BlockMediasPresenter implements IBlockPresenter {
  parse(data: IMediasBo): IMedias {
    if (!data) {
      return null;
    }

    const cards = this.getCards(data.cards);
    const display = data.display || DisplayValues.CARROUSEL;

    return {
      __typename: TypeNameBlockMedia,
      id: data.id,
      title: data.title || '',
      chapo: data.chap,
      cards: cards,
      display: display,
      filters: display === DisplayValues.CARDIFICATION ? this.getFilters(cards) : [],
    };
  }

  private getFilters(cards: (IMediasCardsArticle | IMediasCardsVideo)[]): any[] {
    return [
      {
        title: 'Tous les médias',
        id: 'tous',
      },
      ...[...new Set(cards?.map(card => card['type']).flat())]
        .map(filter => ({
          id: filter,
          title: FilterValues[filter],
        }))
        .filter(filter => filter.id),
    ];
  }

  private getCards(data: IMediasCardBo[]): Array<IMediasCardsArticle | IMediasCardsVideo> {
    return data.map((item: any) => {
      let mainObject = {
        description: RTEPresenter.getRTEText(item.entity.description),
        picto: MediaImagePresenter.getImage(item.entity.picto),
        name: item.entity.name || '',
        date: item.entity.date || '',
        id: item.entity.id || '',
        type: item.entity.type || [],
      };

      // Ajouter des cas spécifiques pour les différents types
      switch (item.entity.__typename) {
        case TypeNameMediaCardArticle:
          return {
            ...mainObject,
            __typename: TypeNameMediaCardArticle,
            pdf: MediaFilePresenter.getFile(item.entity.pdf),
            url: item.entity.url || '',
          };

        case TypeNameMediaCardVideo:
          return {
            ...mainObject,
            __typename: TypeNameMediaCardVideo,
            video: item.entity.video || '',
            videoCover: this.getVideoCover(item.entity.video, item.entity.videoCover),
          };

        default:
          return mainObject;
      }
    });
  }

  private getVideoCover(
    video: string | null,
    videoCover: { entity: { fieldMediaImage: IFieldMediaImageWithOnlyWebp } }
  ): IFieldMediaImage | null {
    return (
      MediaImagePresenter.getImageWebp(videoCover) ??
      (VideoGenerator.generateThumbnailUrl(video) ? { url: VideoGenerator.generateThumbnailUrl(video) } : null)
    );
  }
}
