import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, Signal, StateKey, TransferState, computed, inject, makeStateKey } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HeaderComponent, IHeader } from '@effy-tech/acquisition-core/header';
import { BasicThemedComponentDirective } from '@effy-tech/acquisition-core/shared';
import { EffyButtonComponent, EffyButtonStyle } from '@effy-tech/angular-common/button';
import { EffyIcons } from '@effy-tech/angular-common/icons';
import { EffyLinkComponent, EffyLinkSize, EffyLinkStyle } from '@effy-tech/angular-common/link';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { VideoGenerator, YouTubeThumbnailVideoQuality } from '../../classes/video-generator/video-generator';
import { ConfigService } from '../../modules/config/config.service';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { LoggerService } from '../../services/loggers';
import { MetaTagsService } from '../../services/meta-tags';
import { REQUEST, RESPONSE } from '../../tokens/express.tokens';
import { ISubBlockVideos } from '../block-text/videos/videos';
import { VideosComponent } from '../block-text/videos/videos.component';
import { FooterComponent } from '../footer/footer.component';
import { TitleComponent } from '../title/title.component';
import { ErrorData, ErrorsCode, ErrorsDataMap, MetaDataError, getErrorsMap } from './error-model';
import { getNotFoundCards } from './not-found';

@Component({
  selector: 'nscf-not-found',
  templateUrl: 'not-found.component.html',
  styleUrls: ['not-found.component.scss'],
  imports: [
    HeaderComponent,
    FooterComponent,
    AsyncPipe,
    TitleComponent,
    EffyButtonComponent,
    MatIcon,
    VideosComponent,
    EffyLinkComponent,
    NgOptimizedImage,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent extends BasicThemedComponentDirective implements OnInit {
  private readonly response = inject(RESPONSE, { optional: true });
  private readonly request = inject(REQUEST, { optional: true });
  private readonly title = inject(Title);
  private readonly meta = inject(MetaTagsService);
  private readonly route = inject(ActivatedRoute);
  private readonly configService = inject(ConfigService);
  private readonly transferState = inject(TransferState);
  private readonly logger = inject(LoggerService);
  private readonly deviceManagerService = inject(DeviceManagerService);
  protected readonly environment = this.configService.getEnv();
  protected readonly cards = getNotFoundCards(this.environment);

  private readonly youtubeVideoUrl = 'https://www.youtube.com/watch?v=AUePx4uPrwU';
  private readonly bigViewport = toSignal(this.deviceManagerService.deviceType$().pipe(map(deviceType => deviceType.bigViewPort)));
  protected readonly video: Signal<ISubBlockVideos> = computed(() => ({
    __typename: 'ParagraphVideos',
    video: this.youtubeVideoUrl,
    cover: {
      url: VideoGenerator.generateThumbnailUrl(
        this.youtubeVideoUrl,
        this.bigViewport() ? YouTubeThumbnailVideoQuality.MaxResolution : YouTubeThumbnailVideoQuality.Middle
      ),
      width: 1080,
      height: 810,
    },
  }));

  protected readonly chevronLeftIcon = EffyIcons.CHEVRON_LEFT;
  protected readonly chevronRightIcon = EffyIcons.CHEVRON_RIGHT;
  protected readonly buttonStyle = EffyButtonStyle.TAG;
  protected readonly linkStyle = EffyLinkStyle.YELLOW;
  protected readonly linkSize = EffyLinkSize.LARGE;

  currentErrorData: ErrorData;
  headerDataStatic$ = this.getHeaderData();
  data: {
    statusCode: ErrorsCode;
    from: string;
    messageError: string;
  };
  ngOnInit(): void {
    this.theme = this.configService.getTheme();
    const statusCode = this.getStatusCode();
    const errorsData: ErrorsDataMap = getErrorsMap(this.configService.getErrorsData());

    this.currentErrorData = errorsData?.get(`${statusCode}`);

    if (this.currentErrorData) {
      const { metaData, message }: { metaData: MetaDataError; message: string } = this.currentErrorData;
      this.title.setTitle(metaData?.title);
      this.meta.updateTag({ name: 'description', content: metaData?.description });

      if (this.response) {
        this.response.statusCode = +statusCode;
        this.response.statusMessage = message;
        this.logCustomError(statusCode, message);
      }
    }
  }

  logCustomError(statusCode: ErrorsCode, message: string): void {
    const { headers, method, ip, connection } = this.request || {};
    const requestedUrl = this.route?.snapshot['_routerState']?.url;
    const urlFromPath = this.data.from || '';
    const errorMessageLogged = `${statusCode} ${message} ${urlFromPath}`;
    const log = {
      url: urlFromPath ?? requestedUrl,
      req: {
        headers,
        method,
        ip: ip || connection?.remoteAddress,
      },
    };

    if (statusCode == ErrorsCode.NOT_FOUND_CODE) {
      this.logger.warn(errorMessageLogged, log);
    } else {
      this.logger.error(errorMessageLogged, log);
    }
  }

  private getStatusCode(): ErrorsCode {
    const statusCode: ErrorsCode = this.route.snapshot.data?.statusCode || ErrorsCode.NOT_FOUND_CODE;
    return statusCode;
  }

  private getHeaderData(): Observable<IHeader> {
    return of({
      ...this.configService.getHeaderData(),
      isDesktop$: this.deviceManagerService.deviceType$().pipe(map(deviceType => deviceType.bigViewPort)),
    });
  }
}
