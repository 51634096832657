import { IFieldMediaImage } from './field-media-image';
import { ILink } from './link';
import { IRteText } from './rte-text';

export const IRenovationGaugeMax = 80;

export interface IRenovationType {
  id: number;
  dynamicPercent?: number;
  buttonText?: string;
  buttonUrl?: ILink;
  chapo?: IRteText;
  description?: IRteText;
  gauge?: number;
  gaugeTitle?: IRteText;
  customGaugeTitle?: ICustomGaugeTitle;
  gaugeMax?: number;
  image?: IFieldMediaImage;
  label?: string;
  picto?: IFieldMediaImage;
  title?: string;
}

export interface ICustomGaugeTitle {
  firstPart?: IRteText;
  percent?: string;
  lastPart?: IRteText;
}
