@if (phoneInsert?.phoneNumber || isRegulatory) {
  <button class="container" [class.cursor-default]="isRegulatory" (click)="showContact()">
    <div class="phone-block" [ngClass]="{ 'phone-block--regulatory': isRegulatory }">
      @if (!isRegulatory) {
        <img width="80" height="32" src="assets/backgrounds/expert_team.webp" alt="expert team" class="phone-block__image" />
      }
      <div class="phone-block__phone-part">
        <p class="phone-block__help">
          {{ phoneInsert?.phoneHook }}
        </p>
        @if (isDesktop) {
          <ng-container [ngTemplateOutlet]="phoneText"></ng-container>
        }
      </div>
      <div class="phone-block__text-part">
        @if (!isDesktop) {
          <ng-container [ngTemplateOutlet]="phoneText"></ng-container>
        }
      </div>
      @if (isRegulatory) {
        <nscf-regulatory-phone></nscf-regulatory-phone>
      } @else {
        @defer (on viewport) {
          <mat-icon [svgIcon]="'chevron-right'" class="phone-block__chevron text-blue-power"></mat-icon>
        } @placeholder {
          <span class="size-4"></span>
        }
      }
    </div>
  </button>
}

<ng-template #phoneText>
  @if (phoneInsert?.accompanimentPhone?.value) {
    <div
      class="phone-block__text {{ isDesktop ? 'phone-block__desktop-color' : 'phone-block__mobile-color' }}"
      [innerHTML]="phoneInsert.accompanimentPhone.value"
    ></div>
  }
</ng-template>
