/* eslint-disable */
import { ImageSize } from '../../enums/image-size.enum';
import { IFieldMediaImage } from '../../interfaces/field-media-image';
import { BoUrlService } from '../bo-url/bo-url.service';

// @dynamic
export class ImagesService {
  private static imgRegex: RegExp = /[^"\'=\s]+\.(jpe?g|png|gif|svg|webp)/gi;
  private static currentSize: ImageSize;

  static rewriteImagesUrl(content: string): string {
    // retrieve all images from content
    return content?.replace(this.imgRegex, BoUrlService.rewriteBoUrl);
  }

  static setCurrentImageSize(size: ImageSize): void {
    this.currentSize = size;
  }

  static getCurrentImageSize(): ImageSize {
    return this.currentSize;
  }

  static setBackGroundCoverUrl(mediaCover: IFieldMediaImage): Record<string, unknown> {
    const url = mediaCover ? (mediaCover.largeImage ? mediaCover.largeImage.url : mediaCover.url) : null;
    return {
      'background-image': `url("${url}")`,
    };
  }
}
