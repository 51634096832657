import {
  BlockWork,
  IEligibleRenovations,
  IParagraphWorksBo,
  TypeNameBlockEligibleRenovations,
} from '../../interfaces/eligible-renovations';
import { IRenovationGaugeMax, IRenovationType } from '../../interfaces/renovation-types';
import { LinkPresenter } from '../commons/link.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { RTEPresenter } from '../commons/rte.presenter';
import { IBlockPresenter } from '../presenter';

export class BlockEligibleRenovationsPresenter implements IBlockPresenter {
  parse(data: IParagraphWorksBo): IEligibleRenovations {
    // avoid empty / undefined data
    if (!data) {
      return null;
    }

    const block: IEligibleRenovations = {
      __typename: TypeNameBlockEligibleRenovations,
      id: data.id,
      title: data.title || '',
      subtitle: data.chapo || '',
      renovationTypes: this.getRenovationTypes(data.blockWorks),
    };
    return block;
  }

  private getRenovationTypes(data: BlockWork[]): Array<IRenovationType> {
    const renovationTypes: Array<IRenovationType> = [];
    data.forEach(item => {
      const renovationType: IRenovationType = {
        id: item.entity.id,
        dynamicPercent: 0,
        buttonText: item.entity.buttonText || '',
        buttonUrl: LinkPresenter.getLink(item.entity.buttonUrl),
        chapo: RTEPresenter.getRTEText(item.entity.chapo),
        description: RTEPresenter.getRTEText(item.entity.description),
        gauge: item.entity.gauge || 0,
        gaugeTitle: RTEPresenter.getRTEText(item.entity.gaugeTitle),
        customGaugeTitle: null, // Updated in the component
        gaugeMax: IRenovationGaugeMax,
        image: MediaImagePresenter.getImageWebp(item.entity.image),
        label: item.entity.label || '',
        picto: MediaImagePresenter.getImage(item.entity.picto),
        title: item.entity.title || '',
      };

      renovationTypes.push(renovationType);
    });
    return renovationTypes;
  }
}
