import { MetaDefinition } from '@angular/platform-browser';
import { IHeader } from '@effy-tech/acquisition-core/header';
import { IContactLinks } from '../components/author-contact/author.contact';
import { IBlockText } from '../components/block-text/block-text';
import { IBreadCrumbItems } from '../components/breadcrumb/breadcrumb';
import { IDiscoveryCategorie } from '../components/categorie/discovery-categorie/discovery-categorie';
import { IFocusCategorie } from '../components/categorie/focus-categorie/focus-categorie';
import { ITopPageCategorie } from '../components/categorie/top-page-categorie/top-page-categorie';
import { IChapter } from '../components/chapter/chapter';
import { IFaq } from '../components/faq/faq';
import { IHeadingQuestions } from '../components/heading-questions/heading-questions';
import { IMap } from '../components/map/map';
import { IMedias } from '../components/medias/medias';
import { IBlockNumber } from '../components/numbers/numbers';
import { IOpinions } from '../components/opinions/opinions';
import { IPreHeader } from '../components/pre-header/pre-header';
import { ISuperTopPage } from '../components/super-top-page/super-top-page';
import { IBlocTheme } from '../components/theme/theme';
import { ITopPage } from '../components/top-page/top-page';
import { IBlocTwoImages } from '../components/two-images-block/two-images-block';
import { BOTemplate } from '../enums/bo-template.enum';
import { IArticleMetaInfo } from './article-meta-info';
import { ICardSummary, ICardsSummary } from './cards-summary';
import { IEligibleRenovations } from './eligible-renovations';
import { IFieldMediaImageWithWebp, IFieldMediaImageWithWebpBo } from './field-media-image';
import { ILink } from './link';
import { INewTopPage } from './new-top-page';
import { IRteText } from './rte-text';
import { ITaxonomy } from './taxonomy';

export const TypeNamePageArticle = 'NodePageArticle';
export const TypeNamePageCategorie = 'NodeCategorie';
export const TypeNamePageGuide = 'NodePageGuide';
export const TypeNamePageOffer = 'NodePageOffer';
export const TypeNamePageWork = 'NodePageTravaux';
export const TypeNamePageHub = 'NodePageHub';
export const TypeNamePageManualHub = 'NodePageHubManuelle';

export interface IPageDefault {
  __typename: string;
  title: string | null;
  breadcrumb: IBreadCrumbItems;
  template: BOTemplate;
  header: IHeader;
  metatags: MetaDefinition[];
  urlAnchor?: string;
  preHeader?: IPreHeader | null;
}

export interface MetaTagEntity {
  name: string;
  content: string;
}

export type MetaTagEntities = MetaTagEntity[];

// @TODO : Could be improved, lot of any...
export interface IPageArticleBo {
  __typename: 'NodePageArticle';
  nid: number;
  entityOwner: IDataAuthorBo;
  entityPublished: boolean;
  created: number; // timestamp
  changed: number; // timestamp
  ContentReadTime: string[]; // eg. ['1 minute']
  fieldTemplate: BOTemplate;
  fieldTaxonomy1: any[];
  fieldHeader: any;
  fieldPreHeader: any;
  title: string;
  coverVisibleOnMobile: boolean;
  cover: IFieldMediaImageWithWebpBo;
  map: boolean;
  region: any;
  createdDateShow: boolean;
  showBlockMagazinePart: boolean;
  showBlockMagazinePro: boolean;
  showPostHeader: boolean;
  showSummary: boolean;
  articleLinks: any[];
  moreArticle: any[];
  visibleHeading: boolean;
  headingTheme: any;
  VisibleQuestions: boolean;
  questionsTheme: any;
  fieldBlock: any;
  entityMetatags: MetaTagEntities;
  breadcrumb: any;
}

export interface IPageArticle extends IPageDefault {
  __typename: 'NodePageArticle';
  nid: number;
  taxonomy: Array<ITaxonomy>;
  cover: IFieldMediaImageWithWebp;
  coverVisibleOnMobile?: boolean;
  relateds: IRelatedItem;
  metaInfo: IArticleMetaInfo;
  blocks: Array<
    | IEligibleRenovations
    | IFaq
    | IMedias
    | IBlockNumber
    | IOpinions
    | IBlockText
    | IBreadCrumbItems
    | IBlocTheme
    | IFocusCategorie
    | IBlocTwoImages
  >;
  moreArticle: Array<ICardSummary>;
  map: IMap;
  visibleHeading: boolean;
  visibleQuestions: boolean;
  questions: IHeadingQuestions;
  headingThemeId: string;
  showSummary: boolean;
  urlSegments: string;
  showBlockMagazinePart: boolean;
  showBlockMagazinePro: boolean;
  showPostHeader: boolean;
  showCtaSticky: boolean;
  shouldDisplayAuthorDates: boolean;
  shouldDisplayCtaWithQuestions: boolean;
}

export interface IPageCategorie extends IPageDefault {
  __typename: 'NodeCategorie';
  blocks: Array<
    | IEligibleRenovations
    | IDiscoveryCategorie
    | IFaq
    | IMedias
    | IBlockNumber
    | IOpinions
    | IBlockText
    | IBreadCrumbItems
    | IBlocTheme
    | IFocusCategorie
  >;
  topPage: ITopPageCategorie;
}

export interface IPageGuide extends IPageDefault {
  __typename: 'NodePageGuide';
  chapo: string | null;
  chapters: Array<IChapter>;
}

export interface IPageOffer extends IPageDefault {
  __typename: 'NodePageOffer';
  taxonomy: Array<ITaxonomy>;
  blocks: Array<
    | ITopPage
    | INewTopPage
    | ISuperTopPage
    | IEligibleRenovations
    | IFaq
    | IMedias
    | IBlockNumber
    | IOpinions
    | IBlockText
    | IFocusCategorie
    | IBlocTwoImages
  >;
  map: IMap;
}

export interface IPageWork extends IPageDefault {
  __typename: 'NodePageTravaux';
  title: string;
  blocks: any; // @TODO : à définir
  ctaStickyBlock: any;
  topBlock: any;
  urlSegments: string;
}

export interface IPageHub extends IPageDefault {
  __typename: 'NodePageHub';
  chapo: string | null;
  themeId: string;
  blocks: ICardsSummary;
  promoted: boolean;
  descriptionSeo: IRteText;
  authorContact: IContactLinks;
}

export interface IManualPageHub extends IPageDefault {
  __typename: 'NodePageHubManuelle';
  chapo?: string | null;
  taxonomy: Array<ITaxonomy>;
  relateds: IRelatedItem;
  promoted: boolean;
}

export interface IRelatedItem {
  name: string;
  links: Array<ILink>;
}

export interface IDataAuthorBo {
  fieldAuthor: string;
  fieldAuthorPage: { url: { path: string } } | null;
  fieldDescription: string;
  fieldPosition: string; // Job
  userPicture: {
    alt: string;
    title: string;
    picto: {
      width: number;
      height: number;
      url: string;
      alt: string;
    };
  };
}

export enum PagesName {
  'Article' = 'article',
}

export type IPage = IPageArticle | IPageOffer | IPageGuide | IPageHub | IManualPageHub | IPageCategorie | IPageWork;
