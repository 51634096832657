/* eslint-disable */
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CustomBreakpoints } from '../../enums/custom-breakpoints.enum';
import { Devices, getAllDevicesName } from '../../enums/devices.enum';
import { EffyBreakpoints } from '../../enums/effy-breakpoints.enum';
import { ImageSize, getAllImageSizeName } from '../../enums/image-size.enum';
import { REQUEST } from '../../tokens/express.tokens';
import { ImagesService } from '../images-service/images.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceManagerService {
  private currentDevice: Array<Devices> = [Devices.DESKTOP];
  userAgent: string;
  breakpoints: { [key: string]: boolean };

  constructor(
    @Inject(PLATFORM_ID) readonly platformId,
    private readonly breakpointObserver: BreakpointObserver,
    @Optional() @Inject(REQUEST) readonly request: any
  ) {
    if (!this.isBrowser()) {
      this.userAgent = request.headers['user-agent'];
    }
  }

  getCurrentDevice(): Observable<Array<Devices>> {
    // Get device for SSR
    if (this.userAgent) {
      this.currentDevice = this.getDeviceSSR(this.userAgent);
      return of(this.currentDevice);
    }
    // Get device for CSR
    return this.observeBreakpoint().pipe(
      map(result => {
        if (result.matches) {
          const currentImageSize = getAllImageSizeName().find(size => this.breakpointObserver.isMatched(CustomBreakpoints[size]));
          if (currentImageSize) {
            ImagesService.setCurrentImageSize(ImageSize[currentImageSize] as ImageSize);
          }
          this.currentDevice = getAllDevicesName()
            .filter(device => this.breakpointObserver.isMatched(EffyBreakpoints[device]))
            .map(currentDeviceName => (<any>Devices)[currentDeviceName]);
          // to avoid image breakpoints
          if (this.currentDevice[0] === Devices.DESKTOP && !result.breakpoints[EffyBreakpoints.DESKTOP]) {
            this.currentDevice[0] = Devices.IPAD_PORTRAIT;
          }
          // save breakpointState
          this.breakpoints = result.breakpoints;
          return this.currentDevice;
        }
      })
    );
  }

  deviceType$(): Observable<DeviceType> {
    return this.getCurrentDevice().pipe(
      map(() => ({
        isMobile: this.isMobileOrMobileSE(),
        isDesktop: this.isDesktop(),
        bigViewPort: this.isDesktopOrIPAD_landscape(),
      })),
      distinctUntilChanged(
        (prev, curr) => prev.isMobile === curr.isMobile && prev.isDesktop === curr.isDesktop && prev.bigViewPort === curr.bigViewPort
      )
    );
  }

  isMobileOrMobileSE() {
    return [Devices.MOBILE_SE, Devices.MOBILE].indexOf(this.currentDevice[0]) > -1;
  }

  isDesktopOrIPAD_landscape() {
    return [Devices.DESKTOP, Devices.IPAD_LANDSCAPE].indexOf(this.currentDevice[0]) > -1;
  }

  isDesktop() {
    return [Devices.DESKTOP].indexOf(this.currentDevice[0]) > -1;
  }

  isAtMostWidth(maxWidth: string) {
    return this.breakpointObserver.observe([`(max-width: ${maxWidth})`]).pipe(map(result => result.matches));
  }

  isAtLeastTabletWidth() {
    return this.breakpointObserver.observe(['(min-width: 678px)']).pipe(map(result => result.matches));
  }

  isAtLeastWidth(minWidth: string) {
    return this.breakpointObserver.observe([`(min-width: ${minWidth})`]).pipe(map(result => result.matches));
  }

  isBigDesktop() {
    return this.breakpointObserver.observe(['(min-width: 992px)']).pipe(map(result => result.matches));
  }

  public isMobileOrTablet(): Observable<boolean> {
    if (this.isBrowser()) {
      return this.breakpointObserver.observe(['(max-width: 1023px)']).pipe(map(result => result.matches));
    } else {
      return of(
        [Devices.MOBILE, Devices.MOBILE_LANDSCAPE, Devices.MOBILE_SE, Devices.IPAD_LANDSCAPE, Devices.IPAD_PORTRAIT].indexOf(
          this.currentDevice[0]
        ) > -1
      );
    }
  }

  getDeviceSSR(userAgent: string): Array<Devices> {
    const isIPad = () => Boolean(userAgent.match(/iPad/i));
    const isMobile = () => Boolean(userAgent.match(/Android|webOS|iPhone|iPod|BlackBerry|Windows Phone/i));
    return [isIPad() ? Devices.IPAD_PORTRAIT : isMobile() ? Devices.MOBILE : Devices.DESKTOP];
  }

  private observeBreakpoint(): Observable<BreakpointState> {
    const devicesName = getAllDevicesName();
    const imageSizeName = getAllImageSizeName();

    const breakpoints = devicesName
      .concat(imageSizeName)
      .map(element => (imageSizeName.indexOf(element) > -1 ? CustomBreakpoints[element] : EffyBreakpoints[element]));
    return this.breakpointObserver.observe(breakpoints);
  }

  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  getFullUrlSSr() {
    return this.getBaseUrlSSr() + this.request.originalUrl;
  }

  getBaseUrlSSr() {
    return 'https://' + this.request.get('host');
  }

  getLocation(): string {
    return this.isBrowser() ? window.location.origin : this.getBaseUrlSSr();
  }
}

export interface DeviceType {
  isDesktop: boolean;
  bigViewPort: boolean;
  isMobile: boolean;
}
