import { TypeNamePageArticle } from '../../interfaces/pages';

export const pageMapping = {
  NodePageArticle: () => import('../article/article.component').then(c => c.ArticlePageComponent),
  NodePageGuide: () => import('../guide/guide.component').then(c => c.GuidePageComponent),
  NodePageHub: () => import('../hub/hub.component').then(c => c.HubPageComponent),
  NodePageHubManuelle: () => import('../manual-hub/manual-hub.component').then(c => c.ManualHubComponent),
  NodeCategorie: () => import('../categorie/categorie.component').then(c => c.CategoriePageComponent),
  NodePageTravaux: () => import('../work/work.component').then(c => c.WorkPageComponent),
  NodePageOffer: () => import('../default/default.component').then(c => c.DefaultPageComponent),
};

export const shouldDisplayPhoneInsert = (typename: string) => {
  return !isPageArticle(typename);
};

export const isPageArticle = (typename: string) => {
  return typename === TypeNamePageArticle;
};
