import { AsyncPipe, NgClass } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { EffyButtonComponent, EffyButtonSize } from '@effy-tech/angular-common/button';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { LoadImagesService } from '../../services/load-images.service.ts/load-images.service';
import { IItem, ItemType } from './item';

@Component({
  selector: 'nscf-header-item',
  templateUrl: 'item.component.html',
  styleUrls: ['item.component.scss'],
  imports: [NgClass, AsyncPipe, EffyButtonComponent, MatIcon],
})
export class ItemComponent {
  private readonly loadImagesService = inject(LoadImagesService);
  readonly buttonSize = EffyButtonSize.MEDIUM;

  ItemType = ItemType;
  loadImages$: Observable<boolean> = this.loadImagesService.loadImages$.pipe(
    filter(loadImages => loadImages),
    take(1)
  );
  @Input() item: IItem;

  constructor() {}
}
