import { Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { ConfigService } from '../../modules/config/config.service';
import { DeviceManagerService } from '../device-manager/device-manager.service';
import { MetaTagsTitleService } from './meta-tags-title/meta-tags-title.service';
import { StaticMetaTagsService } from './static-meta-tags/static-meta-tags.service';

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  faceBookMetaNames = ['og:type', 'og:url', 'og:title', 'og:image', 'og:description', 'og:site_name'];

  constructor(
    private readonly meta: Meta,
    private readonly metaTagsTitleService: MetaTagsTitleService,
    private readonly metaServiceStatic: StaticMetaTagsService,
    private readonly configService: ConfigService,
    private readonly deviceManagerService: DeviceManagerService
  ) {}

  addTag({ name, content }: MetaDefinition) {
    this.meta.addTag({ name, content });
  }

  updateTag({ name, content, property }: MetaDefinition) {
    if (name) {
      this.meta.updateTag({ name, content });
    } else {
      this.meta.updateTag({ property, content });
    }
  }

  addTags(tagsArray: MetaDefinition[]) {
    tagsArray.forEach(t => {
      this.updateTag(t);
    });
  }

  setMetatags(tags) {
    const metatags = this.formatUrlTag(tags.filter(v => ['og:description', 'twitter:description'].indexOf(v.name) === -1));
    // Fais un add de metatag fournies par drupal
    metatags.forEach(tag => {
      // facebook
      if (this.faceBookMetaNames.indexOf(tag.name) > -1) {
        this.updateTag({ property: tag.name, content: tag.content });
        return;
      }

      if (tag.name === 'canonical') {
        this.metaServiceStatic.addTag({ rel: tag.name, href: tag.content });
        return;
      }

      if (tag.name === 'title') {
        this.metaTagsTitleService.setTitleTag(tag.content);
      }

      if (tag.name === 'description') {
        this.updateTag({ name: 'twitter:description', content: tag.content });
        this.updateTag({ property: 'og:description', content: tag.content });
      }

      this.updateTag(tag);
    });
  }

  private getLocation(href) {
    const match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return (
      match && {
        href: href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7],
      }
    );
  }

  private formatUrlTag(metatags) {
    return metatags.map(data => {
      const location = this.getLocation(data.content);
      data.content = location ? this.deviceManagerService.getLocation() + location.pathname : data.content;

      if (data.name.indexOf('image') > -1) {
        data.content = this.getImage(data.content);
      }
      return data;
    });
  }

  private getImage(url: string) {
    return url.replace(this.deviceManagerService.getLocation() + '/', this.configService.getEnvironment().url_base_bo);
  }
}
