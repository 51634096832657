import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { EMPTY, fromEvent, Observable, sampleTime, share } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private zone: NgZone = inject(NgZone);
  private platformId = inject(PLATFORM_ID);
  private readonly document = inject(DOCUMENT);

  readonly scroll$: Observable<Event> = isPlatformBrowser(this.platformId)
    ? this.zone.runOutsideAngular(() => fromEvent(this.document.defaultView, 'scroll').pipe(sampleTime(100), share()))
    : EMPTY;
}
