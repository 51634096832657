import { ICtaLink } from '@effy-tech/acquisition-core/header';
import { WebsiteThemes } from '@effy-tech/acquisition-core/shared';
import { EffyIcons } from '@effy-tech/angular-common/icons';

export enum SupportedSocialMedia {
  Facebook = 'facebook',
  Linkedin = 'linkedin',
  X = 'twitter',
  YouTube = 'youtube',
  Instagram = 'instagram',
}

export interface INewFooter {
  logo: ILogo;
  title: string;
  ctaSubject: string;
  cta: ICtaLink;
  siteLogo: ISiteLogo;
  columns: Array<IColumn>;
  socialsLink: ISocialLinks;
  cguLinks: Array<ICguLink>;
  banner?: IBanner;
  isPro?: boolean;
}

export interface ISiteLogo {
  title: string;
  logos: Array<ILogo>;
}

export interface ILogo {
  url: string;
  alt: string;
  link?: string;
  hoverUrl?: string;
  site?: WebsiteThemes;
  icon?: EffyIcons;
}

export interface ISocialLink {
  name: SupportedSocialMedia;
  link: string;
  isHovered?: boolean;
}

export interface IColumn {
  columnLinks: Array<IColumnLink>;
}

export interface IColumnLink {
  mainLink: boolean;
  link: string;
  url?: string;
}

export interface ICguLink {
  text: string;
  url: string;
}

export interface ISocialLinks {
  text: string;
  links: Array<ISocialLink>;
}

export interface IBanner {
  title?: string;
  description?: string;
  cta?: ICtaLink;
}
