import { IRteText } from '../../../interfaces/rte-text';
import { IQueryVariables } from '../../../interfaces/themes-query-variables';

export const TypeNameBlockActuTheme = 'ParagraphActuThemeBlock';

export interface IBlocActuTheme {
  __typename: 'ParagraphActuThemeBlock';
  title: string;
  chapo?: IRteText;
  taxonomy: string;
}

export interface ICardsActuBo {
  data: {
    articleActuGraphqlView: {
      results: ICardBo[];
    };
  };
}

export interface ICardBo {
  title: string;
  url: {
    link: string;
  };
  cover: {
    entity: {
      fieldMediaImage: {
        url: string;
        alt: string;
      };
    };
  };
  creatinDate: string;
  associatedThemes: [
    {
      targetId: string;
      entity: {
        name: string;
        visibility: string;
        id: string;
      };
    },
  ];
}

export function getQueryVariables(currentThemeId: string): IQueryVariables {
  return {
    themes: [currentThemeId],
  };
}
