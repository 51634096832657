import { DynamicComponentData } from '../../interfaces';
import { IPageArticle, IPageWork } from '../../interfaces/pages';
import { TypeNameBlockCtaStickyWithQuestions } from '../cta-sticky-with-questions/cta-sticky-with-questions';

export const TypeNameBlockCtaSticky = 'ParagraphBlocCtaSticky';

export const hasCtaStickyInPage = <T extends IPageWork | IPageArticle>(data: T) =>
  !!data?.blocks?.some(block => [TypeNameBlockCtaSticky, TypeNameBlockCtaStickyWithQuestions].includes(block.__typename));

export const getCtaStickyDynamicComponentData = async (data: IPageWork | IPageArticle): Promise<DynamicComponentData[] | null> => {
  if (!hasCtaStickyInPage(data)) {
    return null;
  }

  const ctaStickyBlock = data?.blocks?.find(block => block.__typename === TypeNameBlockCtaSticky);
  const ctaStickyWithQuestionsBlock = data?.blocks?.find(block => block.__typename === TypeNameBlockCtaStickyWithQuestions);

  return [
    {
      type: await import('../cta-sticky/cta-sticky.component').then(c => c.CtaStickyComponent),
      content: {
        extra: {
          data: { ...(ctaStickyBlock ?? ctaStickyWithQuestionsBlock), shouldDisplayCtaWithQuestions: !!ctaStickyWithQuestionsBlock },
        },
      },
      typename: !!ctaStickyBlock ? TypeNameBlockCtaSticky : TypeNameBlockCtaStickyWithQuestions,
    },
  ];
};

export const removeCtaStickiesFromDynamicComponents = (dynamicComponentsData: DynamicComponentData[]): DynamicComponentData[] => {
  return [
    ...dynamicComponentsData.filter(
      componentData => ![TypeNameBlockCtaSticky, TypeNameBlockCtaStickyWithQuestions].includes(componentData?.typename)
    ),
  ];
};
