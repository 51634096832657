import { DOCUMENT, NgClass } from '@angular/common';
import { Component, Inject, Input, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { IPreHeader, PRE_HEADER_BODY_CLASS } from './pre-header';

@Component({
    selector: 'nscf-pre-header',
    templateUrl: 'pre-header.component.html',
    styleUrls: ['pre-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [NgClass],
    host: {
        ngSkipHydration: 'true',
    }
})
export class PreHeaderComponent implements OnInit {
  @Input() data: IPreHeader;

  constructor(
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, PRE_HEADER_BODY_CLASS);
  }
}
