import { Routes } from '@angular/router';
import { ErrorValue, PreviewComponent } from '@effy-tech/acquisition-core';

export const LANDING_SOLAR_PATH = 'nos-offres/solaire';
export const PAGE_CORP_PATH = 'nous-connaitre';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./static/homepage/homepage.component').then(c => c.HomepageComponent),
    pathMatch: 'full',
  },
  {
    path: 'previewbo',
    component: PreviewComponent,
    pathMatch: 'full',
  },
  {
    path: PAGE_CORP_PATH,
    loadChildren: () => import('./static/page-corp/page-corp.routes').then(m => m.pageCorpRoutes),
  },
  {
    path: 'magazine',
    loadComponent: () => import('./static/magazine/magazine.component').then(c => c.MagazineComponent),
  },
  {
    path: LANDING_SOLAR_PATH,
    loadComponent: () => import('./static/landing-solar/landing-solar.component').then(c => c.LandingSolarComponent),
  },
  {
    path: 'graphql',
    loadChildren: () => import('./static/static.routes').then(m => m.staticRoutes),
    data: {
      statusCode: ErrorValue.NOT_FOUND_CODE.statusCode,
      message: ErrorValue.NOT_FOUND_CODE.message,
    },
  },
  {
    path: 'erreur',
    loadChildren: () => import('./static/static.routes').then(m => m.staticRoutes),
    pathMatch: 'full',
    data: {
      statusCode: ErrorValue.SERVER_ERROR_CODE.statusCode,
      message: ErrorValue.SERVER_ERROR_CODE.message,
    },
  },
  {
    path: '**',
    loadComponent: () => import('./graphql/graphql.component').then(c => c.GraphqlComponent),
  },
];
