@switch (selector) {
  @case ('h1') {
    <h1 class="h1-title" [id]="id">
      {{ title }}
    </h1>
    <ng-container *ngTemplateOutlet="separator"></ng-container>
  }
  @case ('h2') {
    <h2 class="h2-title" [id]="id">
      {{ title }}
    </h2>
    <ng-container *ngTemplateOutlet="separator"></ng-container>
  }
}

<ng-template #separator>
  @if (title && shouldDisplaySeparator()) {
    <effy-liseret class="mt-0" [size]="liseretSize" [centered]="isCentered()" />
  }
</ng-template>
