import { IFieldMediaImage, IFieldMediaImageWithOnlyWebp } from './field-media-image';
import { ILink } from './link';
import { INewTopPageCards } from './new-top-page-cards';
import { IRteText } from './rte-text';

export const TypeNameBlockNewTopPage = 'ParagraphNewTopPage';

export interface ICardArgumentBo {
  entity: {
    id: number;
    title: string;
    image: {
      entity: {
        fieldMediaImage: IFieldMediaImageWithOnlyWebp;
      };
    };
  };
}
export interface INewTopPageBo {
  __typename: 'ParagraphNewTopPage';
  id: number;
  title: string;
  description: {
    value: string;
    format: string;
    processed: string;
  };
  descriptionMobile: {
    value: string;
    format: string;
    processed: string;
  };
  video: string;
  cover: {
    entity: {
      fieldMediaImage: IFieldMediaImageWithOnlyWebp;
    };
  };
  CTABtn: string;
  CTAUrl: ILink;
  CTACondition: string;
  textUnderCta: {
    value: string;
    format: string;
    processed: string;
  };
  cardsArguments: ICardArgumentBo[];
  enableTrustPilot: boolean;
}

export interface INewTopPage {
  __typename: 'ParagraphNewTopPage';
  id?: number;
  title?: string;
  enableTrustPilot: boolean;
  description?: IRteText; // chapo
  buttonText?: string; // CTA-Bouton
  ctaUrl?: ILink;
  termsTitle?: string; // CTA-Conditions générales
  cardsArguments?: Array<INewTopPageCards>;
  video?: string;
  mediaCover?: IFieldMediaImage;
  descriptionMobile?: IRteText;
  textUnderCta?: IRteText;
}
