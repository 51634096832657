import { IOpinions, IOpinionsMaxScore, TypeNameBlockOpinions } from '../../components/opinions/opinions';
import { IOpinionsCard } from '../../components/opinions/opinions-card';
import { LinkPresenter } from '../commons/link.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { IBlockPresenter } from '../presenter';

export class BlockOpinionsPresenter implements IBlockPresenter {
  parse(data: any): IOpinions {
    // avoid empty / undefined data
    if (!data) {
      return null;
    }

    const block: IOpinions = {
      __typename: TypeNameBlockOpinions,
      id: data['id'],
      title: data['title'] || '',
      chapo: data['chapo'] || '',
      maxScore: IOpinionsMaxScore,
      numberOfReview: data['countNotice'] || 0,
      mediaCover: MediaImagePresenter.getImage(data['cover']),
      cardsOpinions: this.getCards(data['blockCardsOpinion']),
    };
    return block;
  }

  private getCards(data: any): Array<IOpinionsCard> {
    const cards: Array<IOpinionsCard> = [];
    data.forEach(item => {
      const card = {
        score: item.entity.scoring || 0,
        delay: item.entity.delay || '',
        context: item.entity.chapo || '',
        title: item.entity.title || '',
        opinion: item.entity.description || '',
        buttonText: item.entity.buttonLabel || '',
        ctaUrl: LinkPresenter.getLink(item.entity.buttonUrl),
      };

      cards.push(card);
    });
    return cards;
  }
}
