@if (headerDataStatic$ | async; as headerDataStatic) {
  <nscf-header [data]="headerDataStatic" [theme]="theme"></nscf-header>
}
<main>
  <nscf-title
    class="flex flex-col items-center"
    title="{{ currentErrorData?.message || 'Une erreur est survenue' }}"
    selector="h1"
    shouldDisplaySeparator
    isCentered
  ></nscf-title>
  <p class="description mb-8 font-600 text-xl md:text-3xl">Voici du contenu qui pourrait vous intéresser</p>
  <a href="/" class="mx-auto mb-16 md:mb-32" effy-button [color]="buttonStyle">
    @defer (on viewport) {
      <mat-icon [svgIcon]="chevronLeftIcon" />
    } @placeholder {
      <span class="text-[32px]">   </span>
    }
    Retour en page d’accueil</a
  >
  @if (cards.length) {
    <section class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 col-span-full mb-16 md:mb-32 mx-auto">
      @for (card of cards; track card.title) {
        <a
          class="flex md:flex-col bg-main-color text-on-main-color rounded-xl overflow-hidden shadow-level-1 hover:shadow-level-2"
          [attr.href]="card.cta.url"
        >
          <div class="relative md:h-[176px] w-full max-w-[40%] md:max-w-none hidden xs:block">
            <img class="object-cover" [ngSrc]="card.cover.url" fill priority [attr.alt]="card.cover.alt" />
          </div>
          <div class="flex flex-col justify-between w-full p-4 md:p-8">
            <p class="font-600 text-md sm:text-2xl md:text-3xl mb-4">{{ card.title }}</p>
            <span class="flex items-end ml-auto md:ml-0" effy-link [color]="linkStyle" [size]="linkSize"
              >{{ card.cta.text }}
              @defer (on viewport) {
                <mat-icon [svgIcon]="chevronRightIcon" />
              } @placeholder {
                <span>></span>
              }
            </span>
          </div>
        </a>
      }
    </section>
  }
  <nscf-title class="flex flex-col items-center" title="Que fait Effy ?" selector="h2" shouldDisplaySeparator isCentered></nscf-title>
  <p class="description mb-16 md:mb-32 font-600 text-lg">
    Notre entreprise s’est donnée pour mission de rendre simples et accessibles les travaux de rénovation énergétique pour tous les
    Français.
  </p>
  @defer (hydrate on idle) {
    <nscf-videos class="mb-16 md:mb-32" [element]="video()" stepNumber="1" [loadedMedias]="{}" />
  }
</main>
<nscf-footer></nscf-footer>
