import { IFaq, IFaqContent, TypeNameBlockFaq } from '../../components/faq/faq';
import { RTEPresenter } from '../commons/rte.presenter';
import { IBlockPresenter } from '../presenter';

export class BlockFaqPresenter implements IBlockPresenter {
  parse(data: any): IFaq {
    // avoid empty / undefined data
    if (!data) {
      return null;
    }

    const block: IFaq = {
      __typename: TypeNameBlockFaq,
      id: data['id'],
      title: data['title'] || '',
      chapo: data['chap'],
      content: this.getFaqContents(data['blockFaq']),
    };
    return block;
  }

  private getFaqContents(data: any): Array<IFaqContent> {
    const faqContents: Array<IFaqContent> = [];
    data.forEach(item => {
      faqContents.push({
        question: item.entity.question || '',
        answer: RTEPresenter.getRTEText(item.entity.answers),
        openState: false,
      });
    });
    return faqContents;
  }
}
