import { InjectionToken } from '@angular/core';
import { IRteText } from '../../interfaces/rte-text';
import { FieldMediaImage } from '../block-text/texts/texts';
import { IMediasCardsArticle, IMediasCardsVideo } from './medias-card';

export const TypeNameBlockMedia = 'ParagraphMedias';
export const IS_SITE_EFFY = new InjectionToken<string>('IS_SITE_EFFY');

export interface IMediasCardBo {
  entity: {
    __typename: 'ParagraphCardsVideos';
    id: number;
    description: {
      value: string;
      format: string;
      processed: string;
    };
    type: Array<any>;
    picto: {
      entity: {
        fieldMediaImage: FieldMediaImage;
      };
    };
    name: string;
    date: any;
    video: any;
    videoCover: {
      entity: {
        fieldMediaImage: FieldMediaImage;
      };
    };
  };
}

export interface IMediasBo {
  __typename: 'ParagraphMedias';
  entityType?: string;
  id: number;
  display: DisplayValues;
  title?: string;
  chap: {
    value: string;
    format: string;
    processed: string;
  };
  cards: IMediasCardBo[];
}

export interface IMedias {
  __typename: 'ParagraphMedias';
  id?: number;
  title?: string;
  isFirstTitle?: boolean;
  chapo?: IRteText;
  cards?: Array<IMediasCardsArticle | IMediasCardsVideo>;
  filters?: Array<IFilter>;
  display?: DisplayValues;
}

export interface IFilter {
  title: string;
  id: string;
}

export enum DisplayValues {
  CARDIFICATION = 'cardification',
  CARROUSEL = 'carrousel',
}

export enum FilterValues {
  television = 'Télévision',
  radio = 'Radio',
  presse = 'Presse',
  web = 'Web',
  tous = 'tous',
}
