import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { isPlatformServer } from '@angular/common';
import { EffyLogoService, EffyLogoType } from '@effy-tech/angular-common/logo';
import { getSiteUrl } from '../../config/urls-sites';
import { Site } from '../../enums/sites.enum';
import { ConfigService } from '../../modules/config/config.service';
import { INTERNAL_ICONS } from './icons.model';

@Injectable({ providedIn: 'root' })
export class IconsService {
  private readonly matIconRegistry = inject(MatIconRegistry);
  private readonly sanitizer = inject(DomSanitizer);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly configService = inject(ConfigService);
  private readonly effyLogoService = inject(EffyLogoService);
  private readonly siteUrl = getSiteUrl(Site.EFFY, this.configService.getEnv());
  private readonly host = isPlatformServer(this.platformId) ? this.siteUrl : '.';

  public init() {
    INTERNAL_ICONS.forEach(name => {
      this.matIconRegistry.addSvgIcon(
        name,
        this.sanitizer.bypassSecurityTrustResourceUrl(`${this.host}/assets/landing-solar/pictos/${name}.svg`)
      );
    });

    this.matIconRegistry.addSvgIcon(
      'site-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.effyLogoService.getUrl(this.configService.getSite(), EffyLogoType.BASE))
    );
  }
}
