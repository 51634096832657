import { ISocialLink, SupportedSocialMedia } from './footer';

export const ctaSubject = 'Un projet de rénovation énergétique ?';
export const socialsLinkText = 'Suivez nous';

export const getSocialLinks = (isPro = false): Array<ISocialLink> => {
  return [
    {
      name: SupportedSocialMedia.Facebook,
      link: isPro ? 'https://www.facebook.com/EffyPartenaires' : 'https://www.facebook.com/effygroupe',
    },
    {
      name: SupportedSocialMedia.Instagram,
      link: 'https://www.instagram.com/effy_groupe/',
    },
    {
      name: SupportedSocialMedia.X,
      link: 'https://x.com/EffyGroupe',
    },
    {
      name: SupportedSocialMedia.Linkedin,
      link: 'https://www.linkedin.com/company/effy',
    },
    {
      name: SupportedSocialMedia.YouTube,
      link: 'https://www.youtube.com/channel/UC6AOIl7ICHej0v31o46majw/videos',
    },
  ];
};
