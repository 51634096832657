<div class="header-link">
  @if (link.url) {
    <a class="header-link__link cursor-pointer" [href]="link.url" title="{{ link.label }}">{{ link.label }}</a>
  }
  @if (!link.url) {
    <div class="header-link__wrapper-link">
      <span class="header-link__link" title="{{ link.label }}">{{ link.label }}</span>
      @if (!isDesktop) {
        <label [for]="'header-link-panel-' + index" class="c-menu-icon-open">
          @defer (on viewport) {
            <mat-icon svgIcon="chevron-right"></mat-icon>
          } @placeholder {
            <span class="size-6">></span>
          }
        </label>
      }
      <input type="radio" [id]="'header-link-panel-' + index" name="menu-list" class="c-menu-radio-list" (change)="onInputChecked()" />
      <nscf-header-menu [menu]="link.menu" [isDesktop]="isDesktop" (goBack)="onGoBack()" [index]="index"></nscf-header-menu>
    </div>
  }
</div>
