import { IBlocTheme, TypeNameBlockTheme } from '../../components/theme/theme';
import { TaxonomyPresenter } from '../commons/taxonomy.presenter';
import { IBlockPresenter } from '../presenter';

export class BlockThemePresenter implements IBlockPresenter {
  parse(data: any): IBlocTheme {
    if (!data) {
      return null;
    }
    return {
      __typename: TypeNameBlockTheme,
      title: data['title'],
      chapo: data['chap'],
      taxonomy: data['theme']?.map(taxonomy => {
        return TaxonomyPresenter.getTaxonomy(taxonomy);
      }),
    };
  }
}
