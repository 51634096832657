<a
  effy-button
  [size]="EffyButtonSize.MEDIUM"
  [color]="buttonStyle()"
  [href]="ctaLink?.url || ''"
  [rel]="ctaLink?.options?.attributes?.rel || ''"
  [target]="ctaLink?.options?.attributes?.target || ''"
  class="w-full smd:w-fit"
>
  @if (btnIcon) {
    <mat-icon [svgIcon]="'account'" class="account-icon"></mat-icon>
  }
  <span class="whitespace-nowrap" [innerHTML]="ctaLink?.title"></span>
  @if (showChevronIcon()) {
    <mat-icon [svgIcon]="'chevron-right'" class="flex w-4 flex-shrink-0"></mat-icon>
  }
</a>
