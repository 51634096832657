import { IFormSTPCard, ISuperTopPage, TypeNameBlockSuperTopPage } from '../../components/super-top-page/super-top-page';
import { LinkPresenter } from '../commons/link.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { IBlockPresenter } from '../presenter';

export class BlockSuperTopPagePresenter implements IBlockPresenter {
  parse(data: any): ISuperTopPage {
    // avoid empty / undefined data
    if (!data) {
      return null;
    }

    const block: ISuperTopPage = {
      __typename: TypeNameBlockSuperTopPage,
      id: data['id'],
      title: data['title'] || '',
      heading: data['chapo'] || '',
      mediaCover: MediaImagePresenter.getImageWebp(data['cover']),
      enableTrustPilot: data.enableTrustPilot,
      ctaLabel: data['CTABtn'] || '',
      ctaUrl: LinkPresenter.getLink(data['CTAUrl']),
      formTitle: data['TitleForm'] || '',
      formDescription: data['descriptionForm'] || '',
      formCards: this.getCards(data['cardForm']),
    };
    return block;
  }

  private getCards(data: any): Array<IFormSTPCard> {
    const cards: Array<IFormSTPCard> = [];
    data.forEach(item => {
      const card = {
        picto: MediaImagePresenter.getImage(item.entity.picto),
        title: item.entity.title || '',
        url: LinkPresenter.getLink(item.entity.url),
      };

      cards.push(card);
    });
    return cards;
  }
}
