import { INewTopPage, INewTopPageBo, TypeNameBlockNewTopPage } from '../../interfaces/new-top-page';
import { INewTopPageCards } from '../../interfaces/new-top-page-cards';
import { LinkPresenter } from '../commons/link.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { RTEPresenter } from '../commons/rte.presenter';
import { IBlockPresenter } from '../presenter';

export class BlockNewTopPagePresenter implements IBlockPresenter {
  parse(data: INewTopPageBo): INewTopPage {
    // avoid empty / undefined data
    if (!data) {
      return null;
    }

    const block: INewTopPage = {
      __typename: TypeNameBlockNewTopPage,
      id: data.id,
      title: data.title || '',
      enableTrustPilot: data.enableTrustPilot,
      description: RTEPresenter.getRTEText(data.description),
      termsTitle: data.CTACondition || '',
      buttonText: data.CTABtn || '',
      ctaUrl: LinkPresenter.getLink(data.CTAUrl),
      cardsArguments: this.getCards(data.cardsArguments),
      mediaCover: MediaImagePresenter.getImageWebp(data.cover),
      descriptionMobile: RTEPresenter.getRTEText(data.descriptionMobile),
      textUnderCta: RTEPresenter.getRTEText(data.textUnderCta),
    };
    return block;
  }

  private getCards(data: any): Array<INewTopPageCards> {
    return data.map((item: any) => ({
      title: item.entity.title || '',
      image: MediaImagePresenter.getImageWebp(item.entity.image),
    }));
  }
}
