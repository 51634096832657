import { inject, Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AcquisitionContactService {
  private readonly injector = inject(Injector);

  handleOpenContactFromWindow() {
    window['contactOpen'] = () => this.openContactLazy();
  }

  async openContactLazy(phoneNumber?: string) {
    const { ContactService } = await import('@effy-tech/appointment/contact');
    const contactService = this.injector.get(ContactService);
    contactService.open('site_clic_2_call_mobile', phoneNumber);
  }
}
