import { environment } from '@acq-environments/environment';
import { EffyFooterService } from '@acq-services/footer/footer.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ApplicationConfig } from '@angular/core';
import { FooterService, provideAcquisitionConfig } from '@effy-tech/acquisition-core';
import { WebsiteThemes } from '@effy-tech/acquisition-core/shared';
import { EffySite } from '@effy-tech/angular-common/site';
import { routes } from './app.routes';
import { errorsList } from './config/errors.config';
import { HEADER_DATA } from './config/header.config';
import { QueriesId_VALUES } from './config/queries';

registerLocaleData(localeFr);

export const appConfig: ApplicationConfig = {
  providers: [
    provideAcquisitionConfig(environment, HEADER_DATA, errorsList, WebsiteThemes.effy, QueriesId_VALUES, EffySite.EFFY, routes),
    {
      provide: FooterService,
      useClass: EffyFooterService,
    },
  ],
};
