import { IMap } from '../../components/map/map';
import { IPosition } from '../../interfaces/position';
export class MapPresenter {
  public static getDataMap(data: any): IMap {
    // avoid empty / undefined data
    if (!data) {
      return <IMap>{};
    }
    const random = Math.floor(Math.random() * 9000) + 1;
    return {
      id: data['id'] || random,
      isActive: data['map'],
      locationMap: data['locationMap'] ? data['locationMap'] : IPosition.LEFT,
      region: data['region'],
    };
  }
}
