import { Component, input, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { EffyButtonComponent, EffyButtonSize, EffyButtonStyle } from '@effy-tech/angular-common/button';
@Component({
  selector: 'nscf-header-cta-button-connected',
  templateUrl: './cta-button-connected.component.html',
  styleUrls: ['./cta-button-connected.component.scss'],
  imports: [EffyButtonComponent, MatIcon],
})
export class CtaButtonConnectedComponent {
  readonly buttonSize = EffyButtonSize.MEDIUM;
  @Input() labelUser: string;
  readonly buttonStyle = input(EffyButtonStyle.PRIMARY);
  link = '/espace-client';
  title = 'Accéder à mon espace';
}
