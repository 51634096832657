import { Directive, HostBinding, Injectable, Input } from '@angular/core';
import { WebsiteThemes } from '../../enums/website-themes';

@Directive({
  selector: '[nscfBasicThemedComponent]',
  // eslint-disable-next-line @angular-eslint/prefer-standalone
  standalone: false,
})
@Injectable()
export class BasicThemedComponentDirective {
  @Input() theme = WebsiteThemes.effy; // init with theme as "effy" by default

  @HostBinding('class') get HeadingClass(): string {
    return this.theme;
  }
}
