/* This method is as close as possible of lodash-es implementation (defaultsDeep) */
export function defaultsDeep<T>(object: any, ...sources: any[]): T {
  if (!object || typeof object !== 'object') {
    return object;
  }

  for (const source of sources) {
    if (!source || typeof source !== 'object') {
      continue;
    }

    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        const sourceValue = source[key];
        const objectValue = object[key];

        if (sourceValue && typeof sourceValue === 'object' && !Array.isArray(sourceValue)) {
          object[key] = defaultsDeep(objectValue && typeof objectValue === 'object' ? objectValue : {}, sourceValue);
        } else if (objectValue === undefined) {
          object[key] = sourceValue;
        }
      }
    }
  }

  return object;
}
