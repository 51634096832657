@if (data.showPreFooter) {
  <nscf-pre-footer></nscf-pre-footer>
}
<footer class="flex flex-col bg-main-color relative pb-[var(--cta-sticky-height)] smd:pb-0">
  <button class="new-footer__arrow-scroll" (click)="scrollToTop()" aria-label="Faire défiler jusqu'en haut">
    @defer (on viewport) {
      <mat-icon [svgIcon]="arrowScrollIcon" class="new-footer__arrow-scroll-icon"></mat-icon>
    } @placeholder {
      <span>scroll</span>
    }
  </button>
  <div class="wrapper-content grid grid-cols-1 w-full smd:grid-cols-[min-content,1fr] gap-10 py-12 smd:py-[88px] mx-auto max-w-[1920px]">
    <div class="flex flex-col items-center smd:items-start gap-20 w-full smd:w-[400px]">
      @defer (on viewport) {
        <mat-icon class="site-logo" [svgIcon]="siteLogo()"></mat-icon>
      } @placeholder {
        <span>{{ siteLogo() }}</span>
      }
      <div class="w-full flex flex-col gap-10 self-start min-w-fit max-w-[400px]">
        <p class="text-2xl smd:text-3xl font-700 mb-0" [innerHTML]="content().ctaSubject | safeHtml"></p>
        <a [href]="content().cta.url" effy-button [color]="ctaStyle" class="w-full">
          {{ content().cta.title }}
        </a>
      </div>
    </div>
    <div
      class="smd:justify-self-end w-fit grid gap-10 grid-cols-1"
      [ngClass]="content().columns.length > 4 ? 'column-more-than-4' : 'column-less-than-4'"
    >
      @for (column of content().columns; track $index) {
        <div class="min-w-[216px] flex flex-col gap-4 items-start">
          @for (columnLink of column.columnLinks; track $index) {
            @if (columnLink.mainLink) {
              @if (columnLink.url) {
                <a [href]="columnLink.url" class="text-md font-700">{{ columnLink.link }}</a>
              } @else {
                <p class="text-md font-700 mb-0">{{ columnLink.link }}</p>
              }
            } @else if (columnLink.url) {
              <a [href]="columnLink.url" class="column-link">
                <span>{{ columnLink.link }}</span>
                @defer (on viewport) {
                  <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
                } @placeholder {
                  <span>></span>
                }
              </a>
            }
          }
        </div>
      }
    </div>
    <div class="flex flex-col gap-4">
      <div class="text-xl font-300">{{ content().siteLogo.title }}</div>
      <div class="w-fit flex flex-wrap md:flex-nowrap gap-6">
        @for (siteLogo of content().siteLogo.logos; track $index) {
          <a [href]="siteLogo.link">
            @defer (on viewport) {
              <mat-icon class="h-8 smd:h-12 w-auto" [svgIcon]="siteLogo.icon"></mat-icon>
            } @placeholder {
              <span>{{ siteLogo.icon }}</span>
            }
          </a>
        }
      </div>
    </div>
    <div class="smd:justify-self-end flex flex-col gap-10">
      <div class="flex flex-col items-center smd:flex-row gap-4 justify-center slg:justify-end smd:gap-10">
        <span class="text-xl font-700">{{ content().socialsLink.text }}</span>
        <div class="flex gap-4">
          @for (socialLink of content().socialsLink.links; track $index) {
            <a [href]="socialLink.link" [attr.aria-label]="socialLink.name" target="_blank" class="flex hover:text-primary-color">
              @defer (on viewport) {
                <mat-icon class="size-8" [svgIcon]="socialLink.name"></mat-icon>
              } @placeholder {
                <span class="size-8">{{ socialLink.name }}</span>
              }
            </a>
          }
        </div>
      </div>
      <div class="flex flex-wrap gap-x-10 gap-y-4 slg:justify-end">
        @for (cguLink of content().cguLinks; track $index) {
          <a class="text-sm font-600 hover:text-primary-color" [href]="cguLink.url">{{ cguLink.text }}</a>
        }
      </div>
    </div>
  </div>
  @if (content()?.banner; as banner) {
    <div
      [ngClass]="bannerTheme()"
      class="wrapper-content flex flex-col items-center smd:flex-row justify-between gap-5 bg-primary-color text-on-primary-color py-6"
    >
      <div class="flex flex-col">
        <p class="text-xl font-700 mb-0">{{ banner?.title }}</p>
        <p class="text-lg font-300 mb-0">{{ banner?.description }}</p>
      </div>
      <a
        [href]="banner?.cta?.url"
        effy-button
        [size]="bannerButtonSize()"
        class="w-full smd:w-[400px]"
        [style.--effy-button-border-color]="!isPro() ? '#ffffff' : null"
      >
        <span>{{ banner?.cta?.title }}</span>
        @defer (on viewport) {
          <mat-icon [svgIcon]="'chevron-right'" class="flex"></mat-icon>
        } @placeholder {
          <span>></span>
        }
      </a>
    </div>
  }
</footer>
