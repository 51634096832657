import { IBlockNumber, IBlockNumberCard, TypeNameBlockNumber } from '../../components/numbers/numbers';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { IBlockPresenter } from '../presenter';

export class BlockNumbersPresenter implements IBlockPresenter {
  parse(data: any): IBlockNumber {
    // avoid empty / undefined data
    if (!data) {
      return null;
    }

    const block: IBlockNumber = {
      __typename: TypeNameBlockNumber,
      id: data['id'],
      title: data['title'] || '',
      chapo: data['chap'],
      cardsNumbers: this.getCards(data['numbers']),
    };
    return block;
  }

  private getCards(data: any): Array<IBlockNumberCard> {
    const cards: Array<IBlockNumberCard> = [];
    data.forEach(item => {
      const card = {
        title: item.entity.title || '',
        chapo: item.entity.chapo || '',
        image: MediaImagePresenter.getImage(item.entity.image),
      };

      cards.push(card);
    });
    return cards;
  }
}
