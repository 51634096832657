@if (data.isDesktop$ | async) {
  <header class="header-v3" [ngClass]="{ 'scroll-over-height': scrollOverHeightHeader() }">
    <div class="header-v3__wrapper">
      <div class="header-v3__wrapper-logo-link">
        <nscf-logo class="header-v3__logo" [logo]="data.logo"></nscf-logo>
        @if (data?.phoneInsert?.phoneNumber || isRegulatory) {
          <nscf-new-phone-block
            [class.hidden]="scrollBack() || !scrollOverHeightHeader() || !data.phoneInsert"
            [phoneInsert]="data.phoneInsert"
            [isDesktop]="true"
            class="phone-bloc phone-block--scroll"
          />
        }
        <div
          [class.hidden]="scrollOverHeightHeader() && !scrollBack() && data.phoneInsert"
          class="effy-container header-v3-connected__container header-v3__main-links"
        >
          <nscf-navbar
            [connection]="data.connection"
            [isDesktop]="true"
            [linkPart]="data.linkPart"
            [navbar]="data.navbar"
            [phoneInsert]="data.phoneInsert"
            [scrollOverHeight]="scrollOverHeightHeader()"
            [theme]="theme"
          >
          </nscf-navbar>
        </div>
      </div>
      <div class="header-v3__right-buttons">
        @if (data.linkPart && !connected()) {
          <nscf-header-cta-button class="btn-devis" [ctaLink]="data.linkPart" [buttonStyle]="secondButtonStyle" />
        }
        @if (isBrowser) {
          <nscf-connection
            (connected)="onConnected($event)"
            [connection]="data.connection"
            [env]="data.env"
            [isPro]="data.isPro"
            [scrollOverHeight]="scrollOverHeightHeader()"
            [theme]="theme"
          />
        }
      </div>
    </div>
  </header>
  @if ((data?.phoneInsert?.phoneNumber || isRegulatory) && !scrollOverHeightHeader()) {
    <nscf-new-phone-block [phoneInsert]="data.phoneInsert" [isDesktop]="true" class="phone-bloc" />
  }
} @else {
  <header class="header-v3 mobile" [ngClass]="{ 'scroll-over-height': scrollOverHeightHeader() }">
    <div class="header-v3__wrapper">
      <div class="mobile-menu-burger">
        <input class="menu-checkbox" id="menu-mobile" name="menu-mobile" type="checkbox" (change)="onMenuMobileChange($event)" />
        <nscf-logo [logo]="data.logo"></nscf-logo>
        <label class="menu-toggle" for="menu-mobile" title="Menu">
          <span class="line line-1"></span>
          <span class="line line-2"></span>
          <span class="line line-3"></span>
        </label>
        <nscf-navbar
          [connection]="data.connection"
          [isDesktop]="false"
          [linkPart]="data.linkPart"
          [navbar]="data.navbar"
          [phoneInsert]="data.phoneInsert"
          [scrollOverHeight]="scrollOverHeightHeader()"
          [theme]="theme"
        ></nscf-navbar>
      </div>
    </div>
  </header>

  <div #mobileRightPanel class="mobile-right-panel">
    <div class="scrollable-panel"></div>
  </div>
}
