/* eslint-disable */
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class PageScrollingService {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly viewport = inject(ViewportScroller);

  handleScrollToAnchorOffset(): void {
    if (isPlatformBrowser) {
      // Header is fixed so we add some space to make anchor target visible
      this.viewport.setOffset([0, 100]);
    }
  }

  scrollTo(target: string, offset: number = this.getOffsetForScroll()): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const options = { behavior: 'smooth', block: 'start' };
    const top = this.getTopWithOffset(target, offset);
    this.scrollToElement(top, options);
    setTimeout(() => {
      const newTop = this.getTopWithOffset(target, offset);
      if (top !== newTop) {
        this.scrollToElement(newTop, options);
      }
    }, 300);
  }

  private scrollToElement(top: number, options) {
    setTimeout(() => {
      window.scrollTo({ top, ...options });
    }, 10);
  }

  private getTopWithOffset(target, offset) {
    const el = document.getElementById(target.replace('#', ''));

    if (el) {
      let top = el.getBoundingClientRect().top + window.pageYOffset;
      if (offset) {
        top -= offset;
      }
      return top;
    }
  }

  private getOffsetForScroll() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isFirefox = userAgent.indexOf('firefox') > -1;
    const SCROLL_PADDING_TOP_IN_PX = 80;
    return isFirefox ? SCROLL_PADDING_TOP_IN_PX + 40 : SCROLL_PADDING_TOP_IN_PX;
  }
}
