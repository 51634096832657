@if ({ loadImages: loadImages$ | async }; as context) {
  <div class="header-item">
    @if (item.type === ItemType.LIST) {
      <div class="header-item__column">
        @for (subItem of item.subItems; track subItem) {
          <div class="header-item__column-item">
            @if (subItem.title) {
              <p class="header-item__column-item-title">
                @if (subItem.url) {
                  @if (subItem.title) {
                    <a [href]="subItem.url">{{ subItem.title }}</a>
                  }
                } @else {
                  {{ subItem.title }}
                }
              </p>
            }
            @if (subItem.links) {
              @for (linkSubItem of subItem.links; track linkSubItem) {
                <div [ngClass]="{ 'no-cta': !item.ctaButton?.url }" class="header-item__column-wrapper-link">
                  @if (linkSubItem.icon && context.loadImages) {
                    <img [src]="linkSubItem.icon" alt="img" class="header-item__column-item-img" />
                  }
                  <a [href]="linkSubItem.url" class="header-item__column-item-link">
                    {{ linkSubItem.label }}
                  </a>
                </div>
              }
            }
          </div>
        }
        @if (item.ctaButton) {
          <a effy-button class="w-full" [size]="buttonSize" [href]="item.ctaButton.url">
            <span class="whitespace-nowrap">{{ item.ctaButton?.label }}</span>
            @defer (on viewport) {
              <mat-icon [svgIcon]="'chevron-right'" class="w-4 flex flex-shrink-0"></mat-icon>
            } @placeholder {
              <span class="size-4">></span>
            }
          </a>
        }
      </div>
    }
    @if (item.type === ItemType.EMPTY) {
      <div class="header-item__column header-item__column--empty"></div>
    }
    @if (item.type === ItemType.IMAGE) {
      <div class="header-item__column">
        @if (item?.link.url) {
          <a [href]="item?.link.url" class="header-item__column-img-link">
            @if (item?.url && context.loadImages) {
              <img [src]="item.url" class="header-item__column--img" alt="" />
            }
          </a>
        } @else {
          @if (item?.url && context.loadImages) {
            <img [src]="item.url" class="header-item__column--img" alt="" />
          }
        }
      </div>
    }
    @if (item.type === ItemType.IMAGE_WAVE) {
      <a [href]="item?.link.url" class="image-item">
        @if (item?.url && context.loadImages) {
          <img class="absolute object-cover h-full w-full z-10" [src]="item.url" alt="" />
        }
        <span class="image-item-text">
          <span class="font-family font-600">{{ item?.link.label }}</span>
          @defer (on viewport) {
            <mat-icon [svgIcon]="'chevron-right'" class="w-4 flex flex-shrink-0"></mat-icon>
          } @placeholder {
            <span class="size-4">></span>
          }
        </span>
      </a>
    }
  </div>
}
