import { Component, inject, input, Input, OnInit, SecurityContext } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { EffyButtonComponent, EffyButtonSize, EffyButtonStyle } from '@effy-tech/angular-common/button';
import { ICtaLink } from './cta-button';

@Component({
  selector: 'nscf-header-cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss'],
  imports: [EffyButtonComponent, MatIcon],
  host: {
    ngSkipHydration: 'true',
  },
})
export class CtaButtonComponent implements OnInit {
  private readonly sanitizer = inject(DomSanitizer);

  @Input() ctaLink: ICtaLink;
  @Input() btnClass = '';
  @Input() btnIcon: string;
  readonly showChevronIcon = input(false);
  readonly buttonStyle = input<EffyButtonStyle>(EffyButtonStyle.PRIMARY);
  readonly EffyButtonSize = EffyButtonSize;

  ngOnInit(): void {
    if (this.ctaLink?.url) {
      this.ctaLink.url = this.sanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        this.sanitizer.bypassSecurityTrustResourceUrl(this.ctaLink.url)
      );
    }
    if (this.ctaLink?.title) {
      this.ctaLink.title = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.ctaLink.title));
    }
  }
}
