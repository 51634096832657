import { defaultsDeep } from '@effy-tech/acquisition-core/shared';
import { IPageCategorie, IPageDefault, TypeNamePageCategorie } from '../../interfaces/pages';
import { LoggerService } from '../../services/loggers';
import { BlockTopPagePresenter } from '../blocks/top-page-categorie/top-page-categorie.presenter';
import { MainBlockPresenter } from '../main-blocks.presenter';
import { IPagePresenter } from '../presenter';
import { PageDefaultPresenter } from './page-default.presenter';

export class PageCategoriePresenter extends PageDefaultPresenter implements IPagePresenter {
  constructor(private readonly logger: LoggerService) {
    super();
  }

  parse(data: any): IPageCategorie {
    const defaultPage: IPageDefault = super.parse(data);
    const mainBlockPresenter = new MainBlockPresenter(this.logger);
    const TopPagePresenter = new BlockTopPagePresenter();
    return defaultsDeep(
      <IPageCategorie>{
        __typename: TypeNamePageCategorie,
        blocks: mainBlockPresenter.parse(data['fieldBlock']),
        topPage: TopPagePresenter.parse({
          titleTopPage: data.titleTopPage,
          chapoTopPage: data.chapoTopPage,
          CtaTopPage: data.CtaTopPage,
          imageTopPage: data.imageTopPage,
          trustPilot: data.trustPilot,
        }),
      },
      defaultPage
    );
  }
}
