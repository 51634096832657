import { IArticlePdfBloc, TypeNameBlockArticlePDFBloc } from '../components/article-blocs/article-pdf/article-pdf';
import { IBlockText, TypeNameBlockTexts } from '../components/block-text/block-text';
import { IBreadCrumbItems } from '../components/breadcrumb/breadcrumb';
import { IBlockCalendly, TypeNameBlockCalendly } from '../components/calendly/calendly';
import { TypeNameBlockActuTheme } from '../components/categorie/actu-theme';
import { TypeNameDiscoveryCategorie } from '../components/categorie/discovery-categorie/discovery-categorie';
import { IFocusCategorie } from '../components/categorie/focus-categorie/focus-categorie';
import { TypeNameBlockCtaStickyWithQuestions } from '../components/cta-sticky-with-questions/cta-sticky-with-questions';
import { TypeNameBlockCtaSticky } from '../components/cta-sticky/cta-sticky';
import { IFaq, TypeNameBlockFaq } from '../components/faq/faq';
import { TypeNameBlockInBrief } from '../components/in-brief/in-brief';
import { IMedias, TypeNameBlockMedia } from '../components/medias/medias';
import { INewsletter, TypeNameBlocNewsletter } from '../components/newsletter/newsletter';
import { IBlockNumber, TypeNameBlockNumber } from '../components/numbers/numbers';
import { IOpinions, TypeNameBlockOpinions } from '../components/opinions/opinions';
import { ISuperTopPage, TypeNameBlockSuperTopPage } from '../components/super-top-page/super-top-page';
import { TypeNameBlockTheme } from '../components/theme/theme';
import { IBlocTopPageForm, TypeNameBlocTopPageForm } from '../components/top-page-parcours/top-page-parcours';
import { ITopPage, TypeNameBlockTopPage } from '../components/top-page/top-page';
import { ITrustpilotCarousel, TypeNameBlockTrustpilot } from '../components/trustpilot-carousel/trustpilot-carousel';
import { IBlocTwoImages, TypeNameBlocTwoImages } from '../components/two-images-block/two-images-block';
import {
  IVerifiedReviewsContainer,
  TypeNameBlockVerifiedReviews,
} from '../components/verified-reviews-container/verified-reviews-container';
import { IEligibleRenovations, TypeNameBlockEligibleRenovations } from '../interfaces/eligible-renovations';
import { TypeNameBlockFocus } from '../interfaces/focus-categorie';
import { INewTopPage, TypeNameBlockNewTopPage } from '../interfaces/new-top-page';
import { LoggerService } from '../services/loggers';
import { ActuThemePresenter } from './blocks/actu-theme/actu-theme.presenter';
import { BlockArticlePdfPresenter } from './blocks/article-pdf/article-pdf.presenter';
import { BlockTextPresenter } from './blocks/block-text.presenter';
import { BlockCalendlyPresenter } from './blocks/calendly.presenter';
import { BlockCtaStickyPresenter } from './blocks/cta-sticky/cta-sticky.presenter';
import { BlockDiscoveryPresenter } from './blocks/discovery-categorie/discovery-categorie.presenter';
import { BlockEligibleRenovationsPresenter } from './blocks/eligible-renovations.presenter';
import { BlockFaqPresenter } from './blocks/faq.presenter';
import { FocusCategoriePresenter } from './blocks/focus-categorie/focus-categorie.presenter';
import { BlockInBriefPresenter } from './blocks/in-brief/in-brief.presenter';
import { BlockMediasPresenter } from './blocks/medias.presenter';
import { BlockNewTopPagePresenter } from './blocks/new-top-page.presenter';
import { BlocNewsletterPresenter } from './blocks/newsletter/bloc-newsletter.presenter';
import { BlockNumbersPresenter } from './blocks/numbers.presenter';
import { BlockOpinionsPresenter } from './blocks/opinions.presenter';
import { BlockSuperTopPagePresenter } from './blocks/super-top-page.presenter';
import { BlockThemePresenter } from './blocks/theme.presenter';
import { BlocTopPageParcoursPresenter } from './blocks/top-page-parcours/top-page-parcours.presenter';
import { BlockTopPagePresenter } from './blocks/top-page.presenter';
import { TrustpilotPresenter } from './blocks/trustpilot/trustpilot.presenter';
import { BlockTwoImagesPresenter } from './blocks/two-images.presenter';
import { BlockVerifiedReviewsContainerPresenter } from './blocks/verified-reviews-container.presenter';
import { IBlockPresenter } from './presenter';

export class MainBlockPresenter {
  constructor(private readonly logger: LoggerService) {}

  parse(
    data: Array<any>
  ): Array<
    | ITopPage
    | INewTopPage
    | ISuperTopPage
    | IEligibleRenovations
    | IFaq
    | IMedias
    | IBlockNumber
    | IOpinions
    | IBlockText
    | IBlocTopPageForm
    | IBreadCrumbItems
    | IVerifiedReviewsContainer
    | INewsletter
    | IFocusCategorie
    | IArticlePdfBloc
    | IBlockCalendly
    | IBlocTwoImages
    | ITrustpilotCarousel
  > {
    const blocks = [];

    data?.forEach((dataObject: any) => {
      const entity = this.determineEntity(dataObject);

      let blockPresenter: IBlockPresenter = null;
      // Determine Presenter according to the typename.
      switch (entity?.__typename) {
        // Top Page
        case TypeNameBlockTopPage:
          blockPresenter = new BlockTopPagePresenter();
          break;

        // New Top Page.
        case TypeNameBlockNewTopPage:
          blockPresenter = new BlockNewTopPagePresenter();
          break;

        // Super Top Page.
        case TypeNameBlockSuperTopPage:
          blockPresenter = new BlockSuperTopPagePresenter();
          break;

        // Renovations.
        case TypeNameBlockEligibleRenovations:
          blockPresenter = new BlockEligibleRenovationsPresenter();
          break;

        // FAQ
        case TypeNameBlockFaq:
          blockPresenter = new BlockFaqPresenter();
          break;

        // Medias
        case TypeNameBlockMedia:
          blockPresenter = new BlockMediasPresenter();
          break;

        // Numbers
        case TypeNameBlockNumber:
          blockPresenter = new BlockNumbersPresenter();
          break;

        // Opinions
        case TypeNameBlockOpinions:
          blockPresenter = new BlockOpinionsPresenter();
          break;

        // Texts
        case TypeNameBlockTexts:
          blockPresenter = new BlockTextPresenter(this.logger);
          break;

        // Verified reviews
        case TypeNameBlockVerifiedReviews:
          blockPresenter = new BlockVerifiedReviewsContainerPresenter();
          break;

        // Focus categorie
        case TypeNameBlockFocus:
          blockPresenter = new FocusCategoriePresenter();
          break;

        // Theme
        case TypeNameBlockTheme:
          blockPresenter = new BlockThemePresenter();
          break;

        // Theme
        case TypeNameBlockActuTheme:
          blockPresenter = new ActuThemePresenter();
          break;

        // Discovery
        case TypeNameDiscoveryCategorie:
          blockPresenter = new BlockDiscoveryPresenter();
          break;

        // Discovery
        case TypeNameBlocNewsletter:
          blockPresenter = new BlocNewsletterPresenter();
          break;

        // Top Page Parcours
        case TypeNameBlocTopPageForm:
          blockPresenter = new BlocTopPageParcoursPresenter();
          break;

        // Two images block.
        case TypeNameBlocTwoImages:
          blockPresenter = new BlockTwoImagesPresenter();
          break;
        case TypeNameBlockArticlePDFBloc:
          blockPresenter = new BlockArticlePdfPresenter();
          break;

        // Calendly
        case TypeNameBlockCalendly:
          blockPresenter = new BlockCalendlyPresenter();
          break;

        // In brief
        case TypeNameBlockInBrief:
          blockPresenter = new BlockInBriefPresenter();
          break;

        // Cta sticky
        case TypeNameBlockCtaSticky:
        case TypeNameBlockCtaStickyWithQuestions:
          blockPresenter = new BlockCtaStickyPresenter();
          break;

        case TypeNameBlockTrustpilot:
          blockPresenter = new TrustpilotPresenter();
          break;

        // @todo: this just to prevent error when BlocCardMedia contributed
        // this block never used in front, i didn't find any trace of it.
        case 'ParagraphBlocCardMedia':
        case 'ParagraphReseauxSociaux': // Old paragraph deleted in front only for now
          break;

        default:
          this.logger.error(`MainBlockPresenter: Block Presenter not found (typename "${entity?.__typename}")`, dataObject);
          break;
      }

      // parse the block data
      if (blockPresenter) {
        const parsedBlock = blockPresenter.parse(entity);
        // avoid empty or undefined blocks
        if (parsedBlock) {
          blocks.push(parsedBlock);
        } else {
          this.logger.warn(`Block empty or undefined (typename "${entity.__typename}")`, entity);
        }
      }
    });
    return blocks;
  }

  private determineEntity(data: any): any {
    if (data.entity?.__typename === 'ParagraphFromLibrary') {
      return data.entity?.fieldReusableParagraph?.entity?.paragraphs?.entity;
    }
    return data['entity'];
  }
}
