import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppInitializerService } from '@effy-tech/acquisition-core';

@Component({
  selector: 'acq-app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  imports: [RouterOutlet],
  host: {
    class: 'app-container',
  },
})
export class AppComponent implements OnInit {
  private readonly appInitializerService = inject(AppInitializerService);

  ngOnInit(): void {
    this.appInitializerService.init('Effy', true);
  }
}
