import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, inject, Input, OnInit, SecurityContext } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AcquisitionContactService } from '@effy-tech/acquisition-core/shared';
import { IHeaderPhoneInsert, PhoneType } from '../header/header';
import { RegulatoryPhoneComponent } from '../regulatory-phone/regulatory-phone.component';
@Component({
  selector: 'nscf-new-phone-block',
  templateUrl: 'phone-block.component.html',
  styleUrls: ['phone-block.component.scss'],
  imports: [NgClass, NgTemplateOutlet, RegulatoryPhoneComponent, MatIconModule],
})
export class PhoneBlockComponent implements OnInit {
  @Input() phoneInsert: IHeaderPhoneInsert;
  @Input() isDesktop: boolean;
  isRegulatory: boolean;
  private readonly sanitizer = inject(DomSanitizer);
  private readonly contactService = inject(AcquisitionContactService);

  ngOnInit(): void {
    this.isRegulatory = this.phoneInsert?.phoneType === PhoneType.Reglementaire;
    if (this.phoneInsert?.accompanimentPhone?.value) {
      this.phoneInsert.accompanimentPhone.value = this.sanitizer.sanitize(
        SecurityContext.HTML,
        this.sanitizer.bypassSecurityTrustHtml(this.phoneInsert.accompanimentPhone?.value)
      );
    }
  }

  async showContact() {
    if (this.phoneInsert?.phoneNumber && !this.isRegulatory) {
      this.contactService.openContactLazy(this.phoneInsert.phoneNumber);
    }
  }
}
