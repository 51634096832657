import { NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, input, Input, OnInit } from '@angular/core';
import { EffyLiseretComponent, EffyLiseretSize } from '@effy-tech/angular-common/liseret';
import { getAlphabeticalString } from '../../utils/string/string.utils';

@Component({
  selector: 'nscf-title',
  templateUrl: 'title.component.html',
  styleUrls: ['title.component.scss'],
  imports: [NgTemplateOutlet, EffyLiseretComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.centered]': 'isCentered()',
  },
})
export class TitleComponent implements OnInit {
  @Input() title: string;
  @Input() id = '';
  @Input() selector: 'h1' | 'h2' = 'h2';

  shouldDisplaySeparator = input(false, { transform: booleanAttribute });
  isCentered = input(false, { transform: booleanAttribute });
  protected readonly liseretSize = EffyLiseretSize.LARGE;

  ngOnInit(): void {
    if (!this.id && this.title) {
      this.id = this.getFallbackId(this.title);
    }
  }

  private getFallbackId(title: string): string {
    return getAlphabeticalString(title, '-');
  }
}
