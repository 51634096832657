<a [href]="link" effy-button [color]="buttonStyle()" [size]="buttonSize" class="w-full smd:w-fit">
  <mat-icon [svgIcon]="'account'" class="account-icon"></mat-icon>
  <div class="flex flex-col text-left whitespace-nowrap">
    <p data-cs-mask class="text-xs h-4 mb-0 font-300">{{ labelUser }}</p>
    <p class="text-sm font-600 h-4 mb-0 flex items-center gap-[2px]">
      <span>{{ title }}</span>
      <mat-icon [svgIcon]="'chevron-right'" class="w-3 flex flex-shrink-0"></mat-icon>
    </p>
  </div>
</a>
