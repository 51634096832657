import { InjectionToken } from '@angular/core';

export enum WebsiteThemes {
  effy = 'effy',
  lpe = 'lpe',
  calculeo = 'calculeo',
  qe = 'qe',
}

export const THEME = new InjectionToken<WebsiteThemes>('website-theme');
