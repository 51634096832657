import { ITopPageCategorie, TypeNameTopPageCategorie } from '../../../components/categorie/top-page-categorie/top-page-categorie';
import { LinkPresenter } from '../../commons/link.presenter';
import { MediaImagePresenter } from '../../commons/media-image.presenter';
import { IBlockPresenter } from '../../presenter';
import { ITopPageCategorieBO } from './top-page-categorie';

export class BlockTopPagePresenter implements IBlockPresenter {
  parse(data: ITopPageCategorieBO): ITopPageCategorie {
    return {
      __typename: TypeNameTopPageCategorie,
      title: data.titleTopPage,
      description: data.chapoTopPage,
      image: MediaImagePresenter.getImageWebp(data.imageTopPage),
      ctaLabel: data.CtaTopPage?.title,
      ctaUrl: LinkPresenter.getLink(data.CtaTopPage),
      trustpilot: data.trustPilot,
    } as ITopPageCategorie;
  }
}
