import { EffyEnvironment } from '@effy-tech/angular-common/environment';
import { getEffyGuideUrl, getEffyOffersUrl } from '../../config/urls-sites';
import { ParcoursUrlUtils } from '../../utils/parcours/parcours.utils';

export const getNotFoundCards = (environment: EffyEnvironment) => [
  {
    cover: {
      alt: 'Homme sur un canapé',
      url: 'assets/not-found/man-on-sofa.webp',
    },
    cta: {
      text: 'Je consulte',
      url: getEffyGuideUrl(environment),
    },
    title: 'Consultez nos guides de la rénovation',
  },
  {
    cover: {
      alt: 'Artisan Effy avec un camion de déménagement',
      url: 'assets/not-found/moving-truck.webp',
    },
    cta: {
      text: 'Je découvre',
      url: getEffyOffersUrl(environment),
    },
    title: 'Découvrez nos offres de rénovation',
  },
  {
    cover: {
      alt: 'Une femme et un homme regardant les offres Effy sur un smartphone',
      url: 'assets/not-found/smartphone-effy.webp',
    },
    cta: {
      text: 'Je simule',
      url: ParcoursUrlUtils.computeWelcome(),
    },
    title: 'Simulez votre projet en 5 minutes',
  },
];
