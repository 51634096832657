import { ErrorsData, ParcoursUrlUtils } from '@effy-tech/acquisition-core';

export const errorsList = <ErrorsData>[
  {
    statusCode: '503',
    message: 'Erreur interne du serveur',
    isServeurError: true,
    metaData: {
      title: 'Erreur 500 Site indisponible | Effy',
      description: 'Le site est actuellement indisponible. Nous serons de retour très bientôt !',
    },
  },
  {
    statusCode: '404',
    message: `Cette page n'existe pas...`,
    isServeurError: false,
    links: [
      {
        url: ParcoursUrlUtils.computeIsolation(),
        label: 'Isoler ma maison au meilleur prix',
      },
      {
        url: '/nos-offres/prime-effy',
        label: 'Demander ma prime Effy',
      },
      {
        url: '/nos-offres/installation-pompe-a-chaleur',
        label: 'Installer ma pompe à chaleur',
      },
      {
        url: '/nos-offres/installation-solaire',
        label: 'Poser des panneaux solaires',
      },
      {
        url: '/nos-offres/changez-votre-chaudiere',
        label: 'Remplacer ma vieille chaudière',
      },
    ],
    metaData: {
      title: 'Erreur 404 Page non trouvée | Effy',
      description:
        "La page que vous recherchez n'existe pas. Découvrez nos autres offres et le guide de la rénovation énergétique sur https://www.effy.fr",
    },
  },
];
