import { InjectionToken } from '@angular/core';
import { Request } from 'express';

export const ERRORS_DATA = new InjectionToken<ErrorsData>('ERRORS_DATA');

export enum ErrorsCode {
  SERVER_ERROR_CODE = '503',
  NOT_FOUND_CODE = '404',
  REDIRECT_URL_CHANGE = '301',
  NO_ERROR = '200',
}

export interface MetaDataError {
  title: string;
  description: string;
}

export interface ErrorData {
  statusCode: string;
  message: string;
  metaData: MetaDataError;
  isServeurError?: boolean;
  links?: Array<{
    url: string;
    label: string;
  }>;
}

export interface LogErrorData {
  label: string;
  data: {
    url: string;
    req: RequestError;
  };
}

export type ErrorsData = Array<ErrorData>;
export type ErrorsDataMap = Map<string, ErrorData>;

export function getErrorsMap(errors_data: ErrorsData): ErrorsDataMap | null {
  if (!errors_data) {
    return null;
  }
  const errorsMap: ErrorsDataMap = new Map();
  for (const errorContent of errors_data) {
    errorsMap.set(errorContent.statusCode, errorContent);
  }
  return errorsMap;
}

export function createLogError(url: string, request: Request, errorData: ErrorData): LogErrorData {
  if (!request) {
    return null;
  }
  const { headers, method, ip, connection } = request || {};
  return {
    label: `${errorData?.statusCode} ${errorData?.message}`,
    data: {
      url,
      req: {
        headers,
        method,
        ip: ip || connection?.remoteAddress,
      },
    },
  };
}

export interface RequestError {
  headers: any;
  method: any;
  ip: string;
}
