import { defaultsDeep } from '@effy-tech/acquisition-core/shared';
import { IPageDefault, IPageWork, TypeNamePageWork } from '../../interfaces/pages';
import { LoggerService } from '../../services/loggers';
import { MainBlockPresenter } from '../main-blocks.presenter';
import { IPagePresenter } from '../presenter';
import { PageDefaultPresenter } from './page-default.presenter';

export class PageWorkPresenter extends PageDefaultPresenter implements IPagePresenter {
  constructor(private readonly logger: LoggerService) {
    super();
  }

  parse(data: any): IPageWork {
    const defaultPage: IPageDefault = super.parse(data);
    // merge defaultPage with extra Page data
    return defaultsDeep(
      <IPageWork>{
        ...this.getCompleteData(data),
      },
      defaultPage
    );
  }

  private getCompleteData(data: any): IPageWork {
    const { blocksWithoutTopMozaic, topBlock } = data['fieldBlock'].reduce(
      (acc, item) => {
        if (item.entity.__typename === 'ParagraphTopPageMozaique') {
          acc.topBlock = item;
        } else {
          acc.blocksWithoutTopMozaic.push(item);
        }
        return acc;
      },
      { topBlock: null, blocksWithoutTopMozaic: [] }
    );

    return {
      __typename: TypeNamePageWork,
      ...data,
      blocks: new MainBlockPresenter(this.logger).parse(blocksWithoutTopMozaic),
      topBlock,
    };
  }
}
