import { inject, Injectable } from '@angular/core';
import { AcquisitionContactService } from '@effy-tech/acquisition-core/shared';
import { EffyIconsService } from '@effy-tech/angular-common/icons';
import { ConfigService } from '../../modules/config/config.service';
import { IconsService } from '../icons/icons.service';
import { PageScrollingService } from '../page-scrolling/page-scrolling.service';
import { RichResultsService } from '../rich-results/rich-results.service';
import { SchemaTypes } from '../rich-results/rich-results.values';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  private readonly configService = inject(ConfigService);
  private readonly iconsService = inject(IconsService);
  private readonly effyIconsService = inject(EffyIconsService);
  private readonly richResultsService = inject(RichResultsService);
  private readonly pageScrollingSerivce = inject(PageScrollingService);
  private readonly contactService = inject(AcquisitionContactService);

  init(nameWebSite: string, withRichResultsOrganization = false): void {
    this.configService.initConfig();
    this.iconsService.init();
    this.effyIconsService.init();
    if (withRichResultsOrganization) {
      this.richResultsService.setJsonLd(SchemaTypes.ORGANIZATION, null);
    }
    this.richResultsService.setTrustpilotRatesJsonLd(nameWebSite);
    this.pageScrollingSerivce.handleScrollToAnchorOffset();
    this.contactService.handleOpenContactFromWindow();
  }
}
