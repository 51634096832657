import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, input, Input, OnInit, Output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { BasicThemedComponentDirective } from '@effy-tech/acquisition-core/shared';
import { EffyButtonComponent, EffyButtonSize, EffyButtonStyle } from '@effy-tech/angular-common/button';
import { EffyIcons } from '@effy-tech/angular-common/icons';
import { Theme } from '@effy-tech/design-system';
import { distinctUntilChanged, map, share } from 'rxjs/operators';
import { SafeHtmlPipe } from '../../pipes/safe-html/safe-html.pipe';
import { DeviceManagerService, DeviceType } from '../../services/device-manager/device-manager.service';
import { PreFooterComponent } from '../pre-footer/pre-footer.component';
import { INewFooter } from './footer';
import { FooterService } from './footer.service';

@Component({
  selector: 'nscf-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [NgClass, SafeHtmlPipe, PreFooterComponent, MatIcon, EffyButtonComponent],
  host: {
    '[class]': 'hostClasses()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent extends BasicThemedComponentDirective implements OnInit {
  private readonly footerService = inject(FooterService);
  protected readonly deviceManagerService = inject(DeviceManagerService);
  readonly isPro = signal(false);
  readonly hostClasses = computed(() => `${this.isPro() ? Theme.PRO : Theme.EFFY}`);
  readonly ctaStyle = EffyButtonStyle.PRIMARY;
  readonly siteLogo = computed<EffyIcons | 'site-logo'>(() => (this.isPro() ? EffyIcons.PRO_ALT_LOGO : 'site-logo'));
  readonly arrowScrollIcon = EffyIcons.CHEVRON_RIGHT;
  readonly isSolar = input(false);
  readonly bannerTheme = computed(() => (this.isPro() ? Theme.EFFY : Theme.PRO));
  readonly bannerButtonSize = computed(() => (this.isDesktop() ? EffyButtonSize.LARGE : EffyButtonSize.MEDIUM));
  readonly isDesktop = toSignal(
    this.deviceManagerService.deviceType$().pipe(
      map((deviceType: DeviceType) => deviceType.isDesktop),
      distinctUntilChanged(),
      share()
    )
  );
  @Input() data = {
    isPro: false,
    showPreFooter: false,
  };
  content = signal<INewFooter>(null);

  @Output() scrollSolar = new EventEmitter<void>();

  ngOnInit(): void {
    this.content.set(this.footerService.getContent(this.data?.isPro));
    this.isPro.set(this.data?.isPro);
  }

  scrollToTop() {
    if (this.isSolar()) {
      this.scrollSolar.emit();
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
