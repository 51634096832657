import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { APP_ID, LOCALE_ID, makeEnvironmentProviders } from '@angular/core';
import { provideClientHydration, withIncrementalHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  InMemoryScrollingOptions,
  Routes,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';
import { IHeader } from '@effy-tech/acquisition-core/header';
import { THEME, WebsiteThemes } from '@effy-tech/acquisition-core/shared';
import { EFFY_ENVIRONMENT } from '@effy-tech/angular-common/environment';
import { EffySite } from '@effy-tech/angular-common/site';
import { WIDGET_CALCULATOR_API_KEY, WIDGET_CALCULATOR_ENV } from '@effy-tech/widget-calculator';
import { IS_SITE_EFFY } from '../../components/medias/medias';
import { ERRORS_DATA, ErrorsData } from '../../components/not-found/error-model';
import { IQueriesId, QUERIES_ID } from '../../graphql/queries-id';
import { BrowserStateInterceptor } from '../../interceptors/browserstate.interceptor';
import { EnvironmentModel } from '../../interfaces/environment.interface';
import { ConsoleLoggerService, LoggerService } from '../../services/loggers';
import { ENVIRONMENT_DATA, HEADER_DATA, SITE } from './config.token';

const scrollConfig: InMemoryScrollingOptions = {
  anchorScrolling: 'enabled',
};

export function provideAcquisitionConfig(
  environments: EnvironmentModel,
  headerData: IHeader,
  errors_data: ErrorsData,
  theme: WebsiteThemes,
  queriesId: IQueriesId,
  site: EffySite,
  routes: Routes
) {
  return makeEnvironmentProviders([
    provideAnimationsAsync(),
    provideClientHydration(withIncrementalHydration()),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideRouter(routes, withEnabledBlockingInitialNavigation(), withInMemoryScrolling(scrollConfig)),
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: APP_ID, useValue: `${site}-front` },
    { provide: SITE, useValue: site },
    { provide: IS_SITE_EFFY, useValue: site === EffySite.EFFY },
    { provide: LoggerService, useClass: ConsoleLoggerService },
    { provide: EFFY_ENVIRONMENT, useValue: environments.env },
    {
      provide: ENVIRONMENT_DATA,
      useValue: environments,
    },
    {
      provide: HEADER_DATA,
      useValue: headerData,
    },
    {
      provide: ERRORS_DATA,
      useValue: errors_data,
    },
    {
      provide: THEME,
      useValue: theme,
    },
    {
      provide: QUERIES_ID,
      useValue: queriesId,
    },
    {
      provide: WIDGET_CALCULATOR_API_KEY,
      useFactory: (environment: EnvironmentModel) => environment.apiKey,
      deps: [ENVIRONMENT_DATA],
    },
    {
      provide: WIDGET_CALCULATOR_ENV,
      useFactory: (environment: EnvironmentModel) => environment.env,
      deps: [ENVIRONMENT_DATA],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    DatePipe,
  ]);
}
