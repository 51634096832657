import { ITopPage, TypeNameBlockTopPage } from '../../components/top-page/top-page';
import { ITopPageCarousel } from '../../components/top-page/top-page-carousel';
import { LinkPresenter } from '../commons/link.presenter';
import { MediaImagePresenter } from '../commons/media-image.presenter';
import { RTEPresenter } from '../commons/rte.presenter';
import { IBlockPresenter } from '../presenter';

export class BlockTopPagePresenter implements IBlockPresenter {
  parse(data: any): ITopPage {
    // avoid empty / undefined data
    if (!data) {
      return null;
    }

    const block: ITopPage = {
      __typename: TypeNameBlockTopPage,
      id: data['id'],
      title: data['title'] || '',
      enableTrustPilot: data.enableTrustPilot,
      subtitle: data['chapo'] || '',
      termsTitle: data['CTACondition'] || '',
      buttonText: data['CTABtn'] || '',
      ctaUrl: LinkPresenter.getLink(data['CTAUrl']),
      mediaCover: MediaImagePresenter.getImageWebp(data['cover']),
      carouselTitle: data['carouselTitle'] || '',
      carousel: this.getCards(data['carrousel']),
    };
    return block;
  }

  private getCards(data: any): Array<ITopPageCarousel> {
    const cards: Array<ITopPageCarousel> = [];
    data.forEach(item => {
      const card = {
        stepNumber: item.entity.stepNumber || 0,
        stepTitle: item.entity.stepTitle || '',
        stepDescription: RTEPresenter.getRTEText(item.entity.stepDescription),
        stepImage: MediaImagePresenter.getImage(item.entity.stepPicto),
      };

      cards.push(card);
    });
    return cards;
  }
}
